import {HandleQuery} from "../common/handle-query";
import {Observable, of} from "rxjs";
import {DataQueryFilters} from "../views/measurements-component/measurements-handler.component";
import {StandaloneHandler} from "../common/standalone-handler";
import {Injectable} from "@angular/core";
import {MomentDateFieldRange} from "../common/date/date-range/date-field-range";
import {DashboardContext} from "../views/dashboard/dashboard.context";
import {DataType} from "@flowmaps/flowmaps-typescriptmodels";
import {CompletenessInfo} from "../app-context";

@Injectable({
    providedIn: "root"
})
export class AppMeasurementsHandler extends StandaloneHandler {

    @HandleQuery("getChartDataQueryFilters")
    getChartDataQueryFilters(): Observable<DataQueryFilters> {
        return of(null);
    }

    @HandleQuery("getTimeRanges")
    getTimeRanges(): Observable<MomentDateFieldRange[]> {
        return of(DashboardContext.defaultRanges);
    }

    @HandleQuery("getEntityConsumptionType")
    getEntityConsumptionType(): Observable<DataType> {
        return of(null);
    }

    @HandleQuery("getCompleteness")
    getCompleteness(): Observable<CompletenessInfo[]> {
        return of([]);
    }
}