import {
    ArcElement,
    BarController,
    BarElement,
    BubbleController,
    CategoryScale,
    Chart,
    Decimation,
    DoughnutController,
    Filler,
    Legend,
    LinearScale,
    LineController,
    LineElement,
    LogarithmicScale,
    PieController,
    PointElement,
    PolarAreaController,
    RadarController,
    RadialLinearScale,
    ScatterController,
    TimeScale,
    TimeSeriesScale,
    Title,
    Tooltip,
} from 'chart.js';
import {lodash} from "../app/common/utils";

import {getCSSVariableValue} from './helpers';

Chart.register(
    ArcElement,
    BarController,
    BarElement,
    BubbleController,
    CategoryScale,
    Decimation,
    DoughnutController,
    Filler,
    Legend,
    LinearScale,
    LineController,
    LineElement,
    LogarithmicScale,
    PieController,
    PointElement,
    PolarAreaController,
    RadarController,
    RadialLinearScale,
    ScatterController,
    TimeScale,
    TimeSeriesScale,
    Title,
    Tooltip,
);

const colors = {
    gray: {
        300: getCSSVariableValue('--bs-chart-gray-300'),
        600: getCSSVariableValue('--bs-chart-gray-600'),
        700: getCSSVariableValue('--bs-chart-gray-700'),
        800: getCSSVariableValue('--bs-chart-gray-800'),
        900: getCSSVariableValue('--bs-chart-gray-900'),
    },
    primary: {
        100: getCSSVariableValue('--bs-chart-primary-100'),
        300: getCSSVariableValue('--bs-chart-primary-300'),
        700: getCSSVariableValue('--bs-chart-primary-700'),
    },
    black: getCSSVariableValue('--bs-dark'),
    white: getCSSVariableValue('--bs-white'),
    transparent: 'transparent',
};

const fonts = {
    base: 'Cerebri Sans',
};

function borderRadius(tick) {
    if (tick.raw > 0) {
        return {
            bottomLeft: 0,
            bottomRight: 0,
            topLeft: 10,
            topRight: 10
        }
    } else {
        return {
            bottomLeft: 10,
            bottomRight: 10,
            topLeft: 0,
            topRight: 0
        }
    }
}

function chartOptions() {
    Chart.defaults.responsive = true;
    Chart.defaults.maintainAspectRatio = false;
    Chart.defaults.locale = "fr";

    // Default
    Chart.defaults.color = getCSSVariableValue('--bs-chart-default-color');
    Chart.defaults.font.family = fonts.base;
    Chart.defaults.font.size = 13;

    // Layout
    Chart.defaults.layout.padding = 0;

    // Legend
    Chart.defaults.plugins.legend.display = false;

    // Point
    Chart.defaults.elements.point.backgroundColor = colors.primary[700];

    // Line
    Chart.defaults.elements.line.tension = 0.4;
    Chart.defaults.elements.line.borderWidth = 3;
    Chart.defaults.elements.line.borderColor = colors.primary[700];
    Chart.defaults.elements.line.backgroundColor = colors.transparent;
    Chart.defaults.elements.line.borderCapStyle = 'round';

    // Bar
    Chart.defaults.elements.bar.backgroundColor = colors.primary[700];
    Chart.defaults.elements.bar.borderWidth = 0;
    Chart.defaults.elements.bar.borderRadius = borderRadius;

    Chart.defaults.elements.bar.borderSkipped = 'middle';
    Chart.defaults.datasets.bar.maxBarThickness = 13;
    Chart.defaults.datasets.bar.barPercentage = 1;
    Chart.defaults.datasets.bar.pointBorderColor = "rgb(0,0,0,0)";
    Chart.defaults.datasets.bar.categoryPercentage = 0.5;

    // Arc
    Chart.defaults.elements.arc.backgroundColor = colors.primary[700];
    Chart.defaults.elements.arc.borderColor = getCSSVariableValue('--bs-chart-arc-border-color');
    Chart.defaults.elements.arc.borderWidth = 5;
    Chart.defaults.elements.arc.borderRadius = 10;

    // Tooltips
    Chart.defaults.plugins.tooltip.enabled = false;
    Chart.defaults.plugins.tooltip.mode = 'index';
    Chart.defaults.plugins.tooltip.intersect = false;
    Chart.defaults.plugins.tooltip.external = externalTooltipHandler;
    Chart.defaults.plugins.tooltip.callbacks.label = externalTooltipLabelCallback;

    // Doughnut
    Chart.defaults.datasets.doughnut.cutout = '50%';
    Chart.defaults.datasets.pie.cutout = '70%';
    Chart.defaults.datasets.doughnut.rotation = 90;

    // yAxis
    Chart.defaults.scales.linear.border = {
        ...Chart.defaults.scales.linear.border, ...{
            display: false,
            dash: [2],
            dashOffset: [2]
        }
    };
    Chart.defaults.scales.linear.grid = {
        ...Chart.defaults.scales.linear.grid,
        ...{color: getCSSVariableValue('--bs-chart-grid-line-color'), drawTicks: false},
    };

    //Chart.defaults.scales.linear.ticks.beginAtZero = true;
    Chart.defaults.scales.linear.ticks.padding = 10;

    // xAxis
    Chart.defaults.scales.category.border = {...Chart.defaults.scales.category.border, ...{display: false}};
    Chart.defaults.scales.category.grid = {
        ...Chart.defaults.scales.category.grid, ...{
            display: false,
            drawTicks: false,
            drawOnChartArea: false
        }
    };
    Chart.defaults.scales.category.ticks.padding = 20;
}

function getOrCreateTooltip(chart) {
    let tooltipEl = chart.canvas.parentNode.querySelector('div');

    if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        tooltipEl.setAttribute('id', 'chart-tooltip');
        tooltipEl.setAttribute('role', 'tooltip');
        tooltipEl.classList.add('popover', 'bs-popover-top');

        const arrowEl = document.createElement('div');
        arrowEl.classList.add('popover-arrow', 'translate-middle-x');

        const contentEl = document.createElement('div');
        contentEl.classList.add('popover-content');

        tooltipEl.appendChild(arrowEl);
        tooltipEl.appendChild(contentEl);
        chart.canvas.parentNode.appendChild(tooltipEl);
    }

    return tooltipEl;
}

function externalTooltipHandler(context) {
    // Tooltip Element
    const {chart, tooltip} = context;
    const fullscreen = chart["fullScreen"] !== undefined ? chart["fullScreen"] : chart.height / screen.height > 0.6;
    const tooltipEl = getOrCreateTooltip(chart);

    // Hide if no tooltip
    if (tooltip.opacity === 0) {
        tooltipEl.style.visibility = 'hidden';
        return;
    }

    // Set Text
    if (tooltip.body) {
        const titleLines = tooltip.title || [];
        const bodyLines = tooltip.body.map((b) => b.lines);

        const headEl = document.createElement('div');
        titleLines.forEach((title) => {
            const headingEl = document.createElement('h3');
            headingEl.classList.add('popover-header', 'text-center', 'text-nowrap', 'notranslate');

            const text = document.createTextNode(title);

            headingEl.appendChild(text);
            headEl.appendChild(headingEl);
        });

        const bodyEl = document.createElement('div');

        const dictionary = lodash.groupBy(tooltip.dataPoints, (d) => d.dataset.tooltip?.stackYear ? d.dataset.tooltip.stackYear : d.dataset.tooltip?.stack || d.dataset.stack);
        Object.entries(dictionary).reverse()
            .map(e => ({
                stack: e[0],
                values: e[1]
            }))
            .forEach(e => {
                const stackEl = document.createElement('div');

                if (Object.keys(dictionary).length > 1) {
                    const headingEl = document.createElement('h4');
                    headingEl.classList.add('text-nowrap', 'm-1', 'mt-2');
                    const isNumeric = !lodash.isNaN(+e.stack);
                    if (isNumeric) {
                        headingEl.appendChild(document.createTextNode("Year"));
                        headingEl.appendChild(document.createTextNode(" "));
                        const valueEl = document.createElement('span');
                        valueEl.classList.add('notranslate');
                        valueEl.appendChild(document.createTextNode(e.stack));
                        headingEl.appendChild(valueEl);
                    } else {
                        headingEl.appendChild(document.createTextNode(e.stack));
                    }
                    stackEl.appendChild(headingEl);
                }

                e.values.sort((a, b) => a.order < b.order ? -1 : 1).forEach(r => {
                    const index = tooltip.dataPoints.findIndex(d => d === r);
                    const dataset = r.dataset;
                    const tooltipLabels = Array.isArray(dataset.tooltip.labelOverride) ? dataset.tooltip.labelOverride : [dataset.tooltip.labelOverride];
                    const colors = tooltip.labelColors[index];

                    tooltipLabels.forEach((l, i) => {
                        const indicatorEl = document.createElement('span');
                        indicatorEl.classList.add('popover-body-indicator');
                        const borderColor = (dataset.tooltip.borderColors && dataset.tooltip.borderColors[i]) || colors.borderColor;

                        if (dataset.type === "line") {
                            indicatorEl.style.color = borderColor;
                            indicatorEl.style.borderWidth = dataset.borderWidth;
                            indicatorEl.style.height = "0rem";
                            indicatorEl.style.borderRadius = 0;
                            indicatorEl.style.border = "1px";
                            indicatorEl.style.borderStyle = dataset.borderDash?.length > 0 ? "dashed" : "solid";
                        } else if (dataset.borderDash?.length > 0) {
                            indicatorEl.style.borderColor = borderColor;
                            indicatorEl.style.borderWidth = dataset.borderWidth;
                            indicatorEl.style.borderStyle = "dashed";
                            indicatorEl.style.height = "0.1rem";
                        } else {
                            indicatorEl.style.borderColor = borderColor;
                            indicatorEl.style.borderWidth = colors.borderWidth;
                            indicatorEl.style.borderStyle = "solid";
                            indicatorEl.style.backgroundColor = Array.isArray(dataset.backgroundColor) ? dataset.backgroundColor[i] : colors.backgroundColor;
                        }

                        const contentEl = document.createElement('div');
                        contentEl.classList.add('popover-body', 'd-flex', 'align-items-center', 'text-nowrap', 'notranslate');
                        contentEl.classList.add(bodyLines.length > 1 ? 'justify-content-left' : 'justify-content-center');
                        const text = document.createTextNode(bodyLines[index][0].split("\n")[i]);

                        contentEl.appendChild(indicatorEl);
                        contentEl.appendChild(text);
                        stackEl.appendChild(contentEl);
                    })
                });

                bodyEl.appendChild(stackEl);
            });

        const rootEl = tooltipEl.querySelector('.popover-content');

        // Remove old children
        while (rootEl.firstChild) {
            rootEl.firstChild.remove();
        }

        // Add new children
        rootEl.appendChild(headEl);
        rootEl.appendChild(bodyEl);
    }

    let {offsetLeft: positionX, offsetTop: positionY} = chart.canvas;

    const padding = {
        x: fullscreen ? 30 : chart.canvas.getBoundingClientRect().left,
        y: 50 + (fullscreen ? chart.height / 2 : chart.canvas.getBoundingClientRect().top)
    };
    const positionRight = fullscreen && tooltipEl.getBoundingClientRect().width > tooltip.caretX;
    if (chart["tooltipCentered"]) {
        positionX = 0;
        tooltip.caretX = chart.canvas.getBoundingClientRect().width / 2;
    }
    // Display, position, and set styles for font
    tooltipEl.style.visibility = 'visible';
    tooltipEl.style.pointerEvents = "none";
    tooltipEl.style.left = positionX + tooltip.caretX + padding.x + 'px';
    tooltipEl.style.top = positionY + padding.y + 'px';
    tooltipEl.style.transform = fullscreen
        ? (positionRight ? 'translateY(-100%) translateX(1rem)' : 'translateX(-100%) translateY(-100%) translateX(-1rem)')
        : 'translateX(-50%) translateY(-100%) translateY(-1rem)';

    const arrowEl = tooltipEl.querySelector("div.popover-arrow");

    tooltipEl.classList.remove("bs-popover-start", "bs-popover-end", "bs-popover-top");
    arrowEl.classList.remove('translate-middle-x');

    if (fullscreen) {
        tooltipEl.classList.add(positionRight ? "bs-popover-end" : "bs-popover-start");
    } else {
        tooltipEl.classList.add("bs-popover-top");
        arrowEl.classList.add('translate-middle-x');
    }
}

function externalTooltipLabelCallback(ctx) {
    let content;
    const scale = ctx.chart.scales[ctx.dataset.yAxisID || 'y'];
    const label = ctx.chart.tooltip.dataPoints.length > 0 && ctx.dataset.label ? `${ctx.dataset.label}: ` : ' ';
    const tooltipSettings = ctx.dataset.tooltip;
    if (scale) {
        const parsedValue = tooltipSettings?.data && tooltipSettings.data.length > ctx.dataIndex
            ? tooltipSettings.data[ctx.dataIndex] : ctx.parsed.y;
        let value = tooltipSettings?.valuesInverted ? -parsedValue : parsedValue;
        if (tooltipSettings?.formatter) {
            value = tooltipSettings.formatter(ctx, value);
        }
        content = label + (ctx.parsed.label ? `${ctx.parsed.label}: ` : '') + value;
        if (ctx.dataset.tooltipSuffix) {
            content += " " + ctx.dataset.tooltipSuffix;
        }
    } else {
        const callbacks = ctx.chart.options.plugins.tooltip.callbacks;
        const before = callbacks.beforeLabel() || '';
        const after = callbacks.afterLabel() || '';
        const labelOverride = callbacks.labelOverride ? callbacks.labelOverride(ctx.dataset) : undefined;
        if (Array.isArray(labelOverride) && ctx.dataset.data.length === labelOverride.length) {
            const multipleContent = labelOverride.map((l, i) => {
                const rawValue = tooltipSettings?.data ? tooltipSettings.data[i] : ctx.dataset.data[i];
                const value = tooltipSettings?.formatter ? tooltipSettings.formatter(ctx, rawValue, i) : rawValue;
                return before + l + value + after;
            });

            content = multipleContent.join("\n");
        } else {
            const rawValue = tooltipSettings?.data ? tooltipSettings.data[ctx.dataIndex] : ctx.raw;
            const value = tooltipSettings?.formatter ? tooltipSettings.formatter(ctx, rawValue, ctx.dataIndex) : ctx.formattedValue;
            content = before + (labelOverride || label) + value + after;
        }
    }

    return content;
}

export {chartOptions}