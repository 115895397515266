import {Component, inject, OnInit} from '@angular/core';
import {EntityPerformanceChartComponent, PerformanceRecord} from "./entity-performance-chart.component";
import {
    AggregatedDataPoint,
    DataType,
    MeasurementsResult,
    Organisation,
    PortfolioMeasurementsResult
} from "@flowmaps/flowmaps-typescriptmodels";
import {DashboardContext} from '../../dashboard/dashboard.context';
import {lodash} from "../../../common/utils";
import {map, mergeMap, Observable, of} from "rxjs";
import {RefdataUtils} from "../../refdata/refdata-utils";
import {ActiveElement, Chart, ChartEvent} from "chart.js";
import {Router} from "@angular/router";
import {AppContext} from "../../../app-context";
import {sendQuery} from "../../../common/app-common-utils";
import {Entity, EntityType} from "../../../handlers/entity";
import {HandleQuery} from "../../../common/handle-query";
import {Handler} from 'src/app/common/handler';
import {
    DataQueryFilters,
    MeasurementsHandlerComponent
} from "../../measurements-component/measurements-handler.component";
import {ChartDataPerMeasurement} from "../../../utils/chart-data-provider";

@Component({
    selector: 'app-location-performance-chart',
    templateUrl: './entity-performance-chart.component.html',
    styleUrls: ['./entity-performance-chart.component.scss']
})
@Handler()
export class LocationPerformanceChartComponent extends EntityPerformanceChartComponent implements OnInit {
    private router: Router = inject(Router);
    selectedDataType: DataType = DataType.electricityConsumption;
    perSquareMeterEnabled = true;

    entityType = (): EntityType => EntityType.location

    get getSelectedDataType() {
        return this.dataType || super.getSelectedDataType;
    }

    ngOnInit() {
        super.ngOnInit();
        this.subscribeTo("getChartDataQueryFilters")
            .subscribe((f: DataQueryFilters) => {
                this.filters = f;
                this.refresh()
            });
    }

    refresh = () => this.sendQuery("getMeasurementsData", this.filters.timeRange)
        .subscribe((d: ChartDataPerMeasurement) => this.setData(d));

    possibleDataTypes = (): DataType[] =>
        [DataType.electricityConsumption, DataType.electricityFeedIn, DataType.electricityConsumptionReactive, DataType.electricityFeedInReactive, DataType.electricityGrossProduction]
            .concat([DataType.gasConsumption, DataType.heatConsumption, DataType.waterConsumption, DataType.chargePointConsumption])
            .concat([DataType.electricityIntermediateConsumption, DataType.waterIntermediateConsumption])
            .concat(AppContext.isAdmin() ? [DataType.gasConsumptionUncorrected] : []);

    createData = (result: ChartDataPerMeasurement): Observable<PerformanceRecord[]> => {
        return this.sendQuery("getByLocation", result)
            .pipe(mergeMap((byLocation: MeasurementsResult[]) =>
                this.getEntities().pipe(map(entities =>
                    Object.entries(lodash.groupBy(byLocation, d => d.entityId))
                        .map(e => {
                            const entity = entities.find(l => l.location.locationId === e[0] || l.location.aliasIds.includes(e[0]));
                            if (!entity) {
                                return null;
                            }
                            const area = RefdataUtils.getLocationArea(entity.location);
                            const value = lodash.sum(e[1].flatMap(d => this.measurementTypes()
                                .flatMap(m => this.getMeasurementsForType(d.measurements, m) || [])
                                .map(r => r.value)));
                            const estimatedValue = lodash.sum(e[1].flatMap(d => this.measurementTypes()
                                .flatMap(m => this.getMeasurementsForType(d.estimatedMeasurements, m) || [])
                                .map(r => r.value)));
                            return entity ? {
                                entityId: e[0],
                                value: this.showPerSquareMeter() ? (area ? lodash.round(value / area, 1) : null) : value,
                                estimatedValue: this.showPerSquareMeter() ? (area ? lodash.round(estimatedValue / area, 1) : null) : estimatedValue,
                                label: RefdataUtils.addressFormatter(entity.location.info.address, false)
                            } : null;
                        }).filter(e => e)))));
    }

    private getEntities = (): Observable<Entity[]> => {
        return this.sendQuery("getChartDataQueryFilters").pipe(mergeMap((q: DataQueryFilters) => {
            const selectedEntities: Entity[] = q?.sources?.filter(t => t.type === this.entityType())
                .map(t => t.source) || [];
            return selectedEntities.length > 0 ? of(selectedEntities) : this.getAllEntities();
        }))
    }

    getAllEntities = (): Observable<Entity[]> => sendQuery("getLocationsAsEntities")

    navigateToEntity = (event: ChartEvent, elements: ActiveElement[], chart: Chart) => {
        const points = chart.getElementsAtEventForMode(event.native, 'nearest', {intersect: true}, true);

        if (points && elements.length > 0) {
            const clickedRecord: PerformanceRecord = this.currentPageRecords[points[0].index];
            if (clickedRecord.entityId) {
                this.sendCommandAndForget("selectEntity", clickedRecord.entityId);
            }
        }
    }

    onHover = (event: ChartEvent, elements: ActiveElement[], chart: Chart) => {
        const target: any = event.native.target;
        target.style.cursor = elements.length > 0 ? "pointer" : "auto";
    }

    private getMeasurementsForType(m: { [P in DataType]?: AggregatedDataPoint[] }, dataType: DataType): AggregatedDataPoint[] {
        const measurements = m[dataType] || [];
        if (dataType === DataType.heatConsumption) {
            return measurements.map(m => (<AggregatedDataPoint>{
                timeRange: m.timeRange,
                value: m.value * DashboardContext.gjToM3Rate
            }));
        }
        return measurements;
    }

    @HandleQuery("getByLocation")
    getByLocation(result: PortfolioMeasurementsResult): Observable<MeasurementsResult[]> {
        return this.sendQuery("getOrganisations")
            .pipe(map((o: Organisation[]) => MeasurementsHandlerComponent.mergeAliasesWithLocation(result, o)))
            .pipe(map(r =>
                (r || []).map(l => {
                    const measurements: { [P in DataType]?: AggregatedDataPoint[] } = {};
                    Object.entries(l.measurements)
                        .filter(e => this.measurementTypes().includes(e[0] as DataType))
                        .forEach(e => measurements[e[0]] = e[1]);
                    const estimatedMeasurements: { [P in DataType]?: AggregatedDataPoint[] } = {};
                    Object.entries(l.estimatedMeasurements)
                        .filter(e => this.measurementTypes().includes(e[0] as DataType))
                        .forEach(e => estimatedMeasurements[e[0]] = e[1]);
                    return <MeasurementsResult>{
                        entityId: l.entityId,
                        measurements: measurements,
                        estimatedMeasurements: estimatedMeasurements
                    };
                })));
    }
}
