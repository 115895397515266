import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {DashboardContext} from "../../../dashboard/dashboard.context";
import {ChartDataProvider} from "../../../../utils/chart-data-provider";
import {MeasurementDataset} from "../../base-measurement-chart";
import {BuildingType, Location} from "@flowmaps/flowmaps-typescriptmodels";
import {EnergyCompassSettings} from "../energy-compass-chart.component";
import {EnergyCompassParisProofLinePlugin} from "../energy-compass-paris-proof-line.plugin";
import {RefdataUtils} from "../../../refdata/refdata-utils";

@Component({
    selector: 'app-energy-compass-table',
    templateUrl: './energy-compass-table.component.html',
    styleUrls: ['./energy-compass-table.component.scss']
})
export class EnergyCompassTableComponent implements OnInit, AfterViewInit {
    context = DashboardContext;
    @Input() dataProvider: ChartDataProvider;
    @Input() energyLabels: string[];
    @Input() settings: EnergyCompassSettings;
    _data: MeasurementDataset[];
    headerTop: string;
    @ViewChild("table") table: ElementRef;
    @Output() tableElement: EventEmitter<ElementRef> = new EventEmitter<ElementRef>();

    constructor(private el: ElementRef) {
    }

    ngOnInit(): void {
        this.dataProvider.aggregatedDataChanged.subscribe(d => {
            this._data = d.datasets.filter(d => !d.dataset.hidden);
            setTimeout(() => this.headerTop = this.getHeaderTop() + "px", 0);
        });
    }

    ngAfterViewInit() {
        setTimeout(() => this.headerTop = this.getHeaderTop() + "px", 1000);
        this.tableElement.emit(this.table);
    }

    records(dataset: MeasurementDataset[]) {
        return dataset.filter(d => d.dataset["buildingType"])
            .flatMap(d =>
                d.dataset.data.map((r, j) => {
                    const buildingType: BuildingType = d.dataset["buildingType"];
                    const energyLabel = this.energyLabels[r["x"]];
                    const location: Location = r["location"];
                    return energyLabel === EnergyCompassParisProofLinePlugin.unknownLabel && !this.settings.showUnknown ? null : {
                        locationName: r["label"],
                        buildingTypeName: buildingType.info.name,
                        energyLabel: energyLabel,
                        value: d.dataset.tooltip.data[j],
                        parisProofValue: buildingType.info.efficiencyScale.ParisProof,
                        area: RefdataUtils.getLocationArea(location)
                    };
                }))
            .filter(d => d);
    }

    getHeaderTop = () => {
        return this.el ? $(this.el.nativeElement).find('#top-header').position()?.top : 0;
    }

    trackBy: any = (index: number, item: any) => {
        return item?.locationName;
    };
}