import {Component, OnInit} from '@angular/core';
import {AppContext} from "../../../../app-context";
import {ChartDataPerMeasurement} from "../../../../utils/chart-data-provider";
import {lodash} from "../../../../common/utils";
import {DashboardContext} from "../../dashboard.context";
import {
    AggregatedDataPoint,
    DataType,
    MeasurementsResult,
    MeasurementUnit,
    MeterType
} from "@flowmaps/flowmaps-typescriptmodels";
import {View} from "../../../../common/view";
import {Handler} from "../../../../common/handler";
import {DataQueryFilters} from "../../../measurements-component/measurements-handler.component";
import {EntityType} from "../../../../handlers/entity";

@Component({
    selector: 'app-energy-summary-widget',
    templateUrl: './energy-summary-widget.component.html',
    styleUrls: ['./energy-summary-widget.component.scss']
})
@Handler()
export class EnergySummaryWidgetComponent extends View implements OnInit {
    appContext = AppContext;
    context = DashboardContext;

    values: SummedValues = {
        electricityConsumption: 0,
        electricityProduction: 0,
        electricityFeedIn: 0,
        gas: 0,
        heat: 0,
        water: 0,
        chargePointConsumption: 0
    }
    private filters: DataQueryFilters;

    ngOnInit() {
        this.subscribeTo("getChartDataQueryFilters")
            .subscribe((f: DataQueryFilters) => {
                this.filters = f;
                return this.sendQuery("getMeasurementsData", f.timeRange)
                    .subscribe((d: ChartDataPerMeasurement) => this.values = this.mergeTotals(d));
            });
    }

    private mergeTotals(data: ChartDataPerMeasurement): SummedValues {
        const onlyMetersSelected = this.filters?.sources?.length && this.filters?.sources.every(s => s.type === EntityType.meter);
        if (!onlyMetersSelected) {
            return (data.totals || []).map(t => this.mergeSummedValues(this.getValues(t.measurements || {}), this.getValues(t.estimatedMeasurements || {})))
                .reduce((a, b) => this.mergeSummedValues(a, b), {
                    electricityConsumption: 0,
                    electricityFeedIn: 0,
                    electricityProduction: 0,
                    gas: 0,
                    heat: 0,
                    water: 0,
                    chargePointConsumption: 0
                });
        } else {
            return (this.getRelevantMeasurements(data.byEntity) || []).map(t => this.mergeSummedValues(this.getValues(t.measurements || {}), this.getValues(t.estimatedMeasurements || {})))
                .reduce((a, b) => this.mergeSummedValues(a, b), {
                    electricityConsumption: 0,
                    electricityFeedIn: 0,
                    electricityProduction: 0,
                    gas: 0,
                    heat: 0,
                    water: 0,
                    chargePointConsumption: 0
                });
        }
    }

    private getValues(measurements: { [P in DataType]?: AggregatedDataPoint[] }): SummedValues {
        const onlyMetersSelected = this.filters?.sources.length && this.filters?.sources.every(s => s.type === EntityType.meter);
        const electricityConsumption = onlyMetersSelected
            ? (measurements.electricityIntermediateConsumption || [])
            : (measurements.electricityConsumption || []).concat(measurements.electricityConsumptionOffPeak);
        const chargePointConsumption = onlyMetersSelected
            ? (measurements.chargePointConsumption || []) .concat(measurements.chargePointIntermediateConsumption)
            : (measurements.chargePointConsumption || []);
        const waterConsumption = onlyMetersSelected
            ? (measurements.waterConsumption || []).concat(measurements.waterIntermediateConsumption)
            : (measurements.waterConsumption || []);
        return {
            electricityConsumption: this.sumData(electricityConsumption),
            electricityFeedIn: this.sumData((measurements.electricityFeedIn || []).concat(measurements.electricityFeedInOffPeak)),
            electricityProduction: this.sumData((measurements.electricityGrossProduction || [])),
            gas: this.sumData(measurements.gasConsumption),
            heat: this.sumData(measurements.heatConsumption),
            water: this.sumData(waterConsumption),
            chargePointConsumption: this.sumData(chargePointConsumption)
        };
    }

    private getRelevantMeasurements(byEntity: MeasurementsResult[]): MeasurementsResult[] {
        return byEntity.filter(measurement => {
            const sourceEntity = this.filters?.sources.find(source => source.source.meter.meterId === measurement.entityId);
            if (!sourceEntity) return false;
            const meter = sourceEntity.source.meter;
            if (!meter) return false;
            const isIntermediate = meter.info?.type === MeterType.INTERMEDIATE;
            if (!isIntermediate && !meter.parentId) return true;

            const parentInSources = meter.parentId && this.filters.sources.some(s => s.source.meter?.meterId === meter.parentId);
            const primaryMeterSelected = !meter.parentId && isIntermediate && this.filters.sources.some(s => s.source.connection?.info.code === sourceEntity.source.connection?.info.code && s.source.meter?.info.type === MeterType.PRIMARY);
            var res = !(parentInSources || primaryMeterSelected);
            return !(parentInSources || primaryMeterSelected);
        });
    }

    private mergeSummedValues = (values: SummedValues, other: SummedValues): SummedValues => {
        return {
            electricityConsumption: values.electricityConsumption + other.electricityConsumption,
            electricityFeedIn: values.electricityFeedIn + other.electricityFeedIn,
            electricityProduction: values.electricityProduction + other.electricityProduction,
            gas: values.gas + other.gas,
            heat: values.heat + other.heat,
            water: values.water + other.water,
            chargePointConsumption: values.chargePointConsumption + other.chargePointConsumption,
        }
    }

    sumData = (dataset: AggregatedDataPoint[]) => dataset ? lodash.sum(dataset.flatMap(d => d?.value)) || 0 : 0;

    electricityConsumptionUnit = () => this.context.getMeasurementUnit(DataType.electricityConsumption);
    electricityProductionUnit = () => this.context.getMeasurementUnit(DataType.electricityFeedIn);
    gasUnit = () => this.context.getMeasurementUnit(DataType.gasConsumption);
    waterUnit = () => this.context.getMeasurementUnit(DataType.waterConsumption);
    heatUnit = () => MeasurementUnit.GJ;
    chargePointConsumptionUnit = () => this.context.getMeasurementUnit(DataType.chargePointConsumption);
}

interface SummedValues {
    electricityConsumption: number;
    electricityFeedIn: number;
    electricityProduction: number;
    gas: number;
    heat: number;
    water: number;
    chargePointConsumption: number;
}