import {Component, ElementRef, Input, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import html2canvas from 'html2canvas';
import {AppContext} from "../../../app-context";
import {exportTableToExcel} from "../../../common/upload/excel.utils";
import {ConnectionType, TimeRange, TimeResolution} from "@flowmaps/flowmaps-typescriptmodels";
import {RefdataUtils} from "../../refdata/refdata-utils";
import {SourceInfo} from "../../../utils/source-providers/sources-provider";
import {AppCommonUtils} from "../../../common/app-common-utils";
import {TranslateDirective} from "../../../common/utils/translate.directive";
import {
    connectionCsvDataToJson,
    downloadRawConnectionData
} from "../../refdata/connections/connections-overview/connections-overview.component";
import {Handler} from "../../../common/handler";
import {View} from "../../../common/view";
import moment from "moment";
import {DataQueryFilters} from "../../measurements-component/measurements-handler.component";
import {EntityType} from "../../../handlers/entity";

@Component({
    selector: 'app-chart-download-button',
    templateUrl: './chart-download-button.component.html',
    styleUrls: ['./chart-download-button.component.css']
})
@Handler()
export class ChartDownloadButton extends View implements OnInit {
    appContext = AppContext;
    @Input() element: HTMLElement | ElementRef;
    @Input() tableElement: ElementRef;
    @Input() fileName: string;

    selectedConnections: SourceInfo[] = [];
    rawDataDownloadThreshold: number = 5;
    @Input() connectionTypes: ConnectionType[] | string[];
    timeRange: TimeRange;

    ngOnInit() {
        this.subscribeTo("getChartDataQueryFilters").subscribe((f: DataQueryFilters) => {
            this.timeRange = f.timeRange;
            this.selectedConnections = (f.allSelectedSources || [])
                .filter(s => s.type === EntityType.connection)
                .filter(s => (this.connectionTypes || []).includes(s.connectionType));
        });
    }

    downloadImage() {
        const subject = new Subject<void>();
        const process = AppCommonUtils.waitForProcess(subject);
        process.subscribe();
        const element: HTMLElement = this.element ? this.element['nativeElement'] || this.element : document.body;
        const dropdownMenu = element.querySelector('.dropdown-menu') as HTMLElement;
        if (dropdownMenu) {
            dropdownMenu.style.display = 'none';
        }
        html2canvas(element).then(canvas => {
            try {
                let downloadLink = document.createElement('a');
                downloadLink.setAttribute('download', this.fileName || 'screenshot.png');
                canvas.toBlob(function (blob) {
                    let url = URL.createObjectURL(blob);
                    downloadLink.setAttribute('href', url);
                    downloadLink.click();
                });
            } finally {
                subject.next();
            }
        });
    }

    get rawDataExportSupported(): boolean {
        return (this.connectionTypes || []).filter(a => a).length > 0;
    }

    get toManyConnectionsTooltip(): string {
        return `${TranslateDirective.getTranslation('Data download is only possible below a selection of')} ${this.rawDataDownloadThreshold + 1} ${TranslateDirective.getTranslation('connections').toLowerCase()}`;
    }

    get duration(): moment.Duration {
        return AppContext.durationForTimeRange(this.timeRange);
    }

    downloadTable = () => exportTableToExcel(this.tableElement);

    belowRawDataThreshold = () => this.selectedConnections.length && this.selectedConnections.length <= this.rawDataDownloadThreshold;

    downloadRawData = (resolution: TimeResolution | string) => downloadRawConnectionData(this.timeRange, resolution as TimeResolution,
        this.selectedConnections.map(c => c.source), connectionCsvDataToJson, RefdataUtils.connectionsComparator);
}
