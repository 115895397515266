import {Component, OnInit} from '@angular/core';
import {Handler} from "src/app/common/handler";
import {ConnectionType, DataType} from "@flowmaps/flowmaps-typescriptmodels";
import {BaseMeasurementChartComponent} from "../base-measurement-chart";
import {ChartOptionType} from "../../dashboard/dashboard.types";

@Component({
  selector: 'app-electricity-chart',
  templateUrl: './electricity-chart.component.html',
  styleUrls: ['./electricity-chart.component.scss']
})
@Handler()
export class ElectricityChartComponent extends BaseMeasurementChartComponent implements OnInit {
  chartOptionType: ChartOptionType = ChartOptionType.Electricity;
  mainConsumptionType: DataType = DataType.electricityConsumption;
  mainConnectionType: ConnectionType = ConnectionType.Electricity;
  connectionTypes: ConnectionType[] = [ConnectionType.Electricity];
  feedInPowerTypes: DataType[] = [DataType.electricityFeedInPower];
  contractedCapacityRequiredForPower: boolean = true;

  openModal() {
    super.openModal(ElectricityChartComponent);
  }

  productionDataTypes = (): DataType[] => this.showFeedIn() ? [DataType.electricityFeedIn, DataType.electricityFeedInOffPeak] : [];

  measurementTypes = (): DataType[] => {
    let baseMeasurements = this.showConsumption() ? [DataType.electricityConsumption, DataType.electricityConsumptionOffPeak] : [];
    if (this.options.showReactive) {
      baseMeasurements = baseMeasurements.concat(DataType.electricityConsumptionReactive, DataType.electricityFeedInReactive,
          DataType.electricityConsumptionReactiveOffPeak, DataType.electricityFeedInReactiveOffPeak);
    }
    if (this.options.showGrossProduction) {
      baseMeasurements = baseMeasurements.concat([DataType.electricityGrossProduction]);
      if (this.options.showReactive) {
        baseMeasurements = baseMeasurements.concat([DataType.electricityGrossProductionReactive]);
      }
    }
    if (this.showConsumption() && this.groupByEntityIdEnabled()) {
      baseMeasurements = baseMeasurements.concat([DataType.electricityIntermediateConsumption]);
      if (this.options.showReactive) {
        baseMeasurements = baseMeasurements.concat(DataType.electricityIntermediateConsumptionReactive);
      }
    }
    return baseMeasurements;
  };

  measurementIntermediateLink = (): Map<DataType, DataType[]> => {
    const intermediateValues = [DataType.electricityIntermediateConsumption]
        .concat(this.options.showGrossProduction ? [DataType.electricityGrossProduction] : []);
    return new Map<DataType, DataType[]>()
        .set(DataType.electricityConsumption, intermediateValues)
        .set(DataType.electricityConsumptionOffPeak, intermediateValues);
  };

  consumptionProductionLink = (): Map<DataType, DataType> => new Map<DataType, DataType>()
      .set(DataType.electricityConsumption, DataType.electricityFeedIn)
      .set(DataType.electricityConsumptionOffPeak, DataType.electricityFeedInOffPeak);

  measurementTypesMapped = (): {[key: string]: DataType[]} => {
    const measurementTypes = {
      [DataType.electricityConsumption]: [DataType.electricityConsumption, DataType.electricityConsumptionOffPeak],
      [DataType.electricityIntermediateConsumption]: [DataType.electricityIntermediateConsumption],
      [DataType.electricityGrossProduction]: [DataType.electricityGrossProduction]
    };
    if (this.options.showReactive) {
      measurementTypes[DataType.electricityConsumptionReactive] = [DataType.electricityConsumptionReactive, DataType.electricityConsumptionReactiveOffPeak];
      measurementTypes[DataType.electricityFeedInReactive] = [DataType.electricityFeedInReactive, DataType.electricityFeedInReactiveOffPeak];
    }
    if (this.options.showGrossProduction) {
      measurementTypes[DataType.electricityGrossProduction] = [DataType.electricityGrossProduction];
      if (this.options.showReactive) {
        measurementTypes[DataType.electricityGrossProductionReactive] = [DataType.electricityGrossProductionReactive];
      }
    }
    return measurementTypes;
  }

  productionDataTypesMapped = (): {[key: string]: DataType[]} => ({
    [DataType.electricityFeedIn]: [DataType.electricityFeedIn, DataType.electricityFeedInOffPeak],
  });

  splitOffPeakChange(splitOffPeak: boolean) {
    this.options.splitOffPeak = splitOffPeak;
    this.refreshData(true);
  }

  showConsumptionChange(showConsumption: boolean) {
    this.options.showConsumption = showConsumption;
    this.options.nettedConsumption = showConsumption ? this.options.nettedConsumption : false;
    this.refreshData(true);
  }

  showFeedInChange(showFeedIn: boolean) {
    this.options.showFeedIn = showFeedIn;
    this.refreshData(true);
  }

  nettedConsumptionChange(nettedConsumption: boolean) {
    this.options.nettedConsumption = nettedConsumption;
    this.refreshData(true);
  }

  showReactiveChange(showReactive: boolean) {
    this.options.showReactive = showReactive;
    this.refreshData(true);
  }

  protected getYAxis() {
    return this.options.splitOffPeak ? "y1" : "y1Aggregated";
  }
}
