import {Component, Input, OnInit} from '@angular/core';
import {ConnectionInfo, MoveConnection} from "@flowmaps/flowmaps-typescriptmodels";
import {AppContext} from "../../../../app-context";
import {RefdataUtils} from "../../refdata-utils";
import {View} from "../../../../common/view";
import {ConnectionComponentData} from "../connection-details/connection-details.component";
import {Entity} from "../../../../handlers/entity";
import {Observable} from "rxjs";
import lodash from "lodash";

@Component({
  selector: 'app-move-connection-modal',
  templateUrl: './move-connection-modal.component.html',
  styleUrls: ['./move-connection-modal.component.css']
})
export class MoveConnectionModalComponent extends View implements OnInit {
  appContext = AppContext;
  refDataUtils = RefdataUtils;
  info: ConnectionInfo;
  @Input() data: ConnectionComponentData;
  location: Entity;

  ngOnInit(): void {
    this.info = this.data.connection?.info || <ConnectionInfo>{};
    this.location = lodash.cloneDeep(new Entity(this.data.organisation, this.data.location));
  }

  searchLocation = (term: string): Observable<Entity[]> =>
     this.sendQuery("searchLocations", term);

  moveConnection = () => {
    return this.sendCommand("com.flowmaps.api.organisation.MoveConnection", <MoveConnection> {
      organisationId: this.data.organisation.organisationId,
      locationId: this.data.location.locationId,
      connectionId: this.data.connection?.connectionId,
      targetLocationId: this.location.location.locationId
    })
  };
}
