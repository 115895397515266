import {Component, inject, OnInit} from '@angular/core';
import {AppContext} from "../../app-context";
import {Alert, GetMyReports, Report} from "@flowmaps/flowmaps-typescriptmodels";
import {AuthService} from '../../utils/auth.service';
import {View} from "src/app/common/view";
import {Observable} from "rxjs";
import {Handler} from "src/app/common/handler";
import {EncryptComponent} from "../admin/encrypt/encrypt.component";
import {RefdataUtils} from "../refdata/refdata-utils";
import {DashboardContext} from "../dashboard/dashboard.context";
import {openModal} from "../../common/app-common-utils";
import {Dashboard} from "../dashboard/dashboard.types";
import {cloneObject} from "../../common/utils";
import {ReportComponent} from "../reports/report/report.component";
import {ReportUtils} from "../reports/report-utils";
import lodash from "lodash";

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss']
})
@Handler()
export class MainMenuComponent extends View implements OnInit {
  appContext = AppContext;
  reportUtils = ReportUtils;
  reports: Report[];
  authService: AuthService = inject(AuthService);

  openErrors: Observable<Error[]>;
  openAlerts: Observable<Alert[]>;

  defaultReport: Report = cloneObject(ReportComponent.defaultReport);

  ngOnInit() {
    this.getReports();
    this.openErrors = this.subscribeTo("getOpenErrors");
    this.openAlerts = this.subscribeTo("getOpenAlerts");
  }

  getDashboardsForType = () => {
    return lodash.sortBy(AppContext.userProfile?.dashboards || [], (d: Dashboard) => d.info.name);
  }

  getReports = () => this.sendQuery("com.flowmaps.api.reporting.GetMyReports", <GetMyReports>{}, {caching: false})
      .subscribe(r => this.reports = r.sort(RefdataUtils.reportsComparator.compare));

  encoded = (info: Dashboard): string => {
    delete info.info.timeRange["label"];
    delete info.info.timeRange["resolution"];
    return DashboardContext.dashboardToBase64(info);
  };

  trackBy: any = (index: number, item: Dashboard) => item?.dashboardId;

  openEncrypt = () => openModal(EncryptComponent, {})

  stopImpersonatingUser = () => this.authService.stopImpersonating();
}
