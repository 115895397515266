import {Component, OnInit} from '@angular/core';
import {EntityPerformanceChartComponent, PerformanceRecord} from "./entity-performance-chart.component";
import {DataType, MeterType} from "@flowmaps/flowmaps-typescriptmodels";
import {lodash, none} from "../../../common/utils";
import {map, mergeMap, Observable, of} from "rxjs";
import {RefdataUtils} from "../../refdata/refdata-utils";
import {Entity, EntityType} from "../../../handlers/entity";
import {sendQuery} from "../../../common/app-common-utils";
import {Measurements} from "../../measurements-component/measurements";
import {DataQueryFilters} from "../../measurements-component/measurements-handler.component";
import {Handler} from "../../../common/handler";
import {ChartDataPerMeasurement} from "../../../utils/chart-data-provider";
import {Chart} from "chart.js";
import {ActiveElement, ChartEvent} from "chart.js/dist/types";

@Component({
    selector: 'app-meter-performance-chart',
    templateUrl: './entity-performance-chart.component.html',
    styleUrls: ['./entity-performance-chart.component.scss']
})
@Handler()
export class MeterPerformanceChartComponent extends EntityPerformanceChartComponent implements OnInit {
    selectedDataType: DataType = DataType.electricityIntermediateConsumption;
    perSquareMeterEnabled = true;

    ngOnInit() {
        super.ngOnInit();
        this.options.selectedDataType = this.selectedDataType;
        this.sendQuery("getEntityConsumptionType").subscribe(e => {
            if (e) {
                this.options.selectedDataType = this.selectedDataType = e;
            }
        });
        this.subscribeTo("getChartDataQueryFilters")
            .subscribe((f: DataQueryFilters) => {
                this.filters = f;
                this.refresh()
            });
    }

    get getSelectedDataType() {
        const selectedDataType = super.getSelectedDataType;
        return selectedDataType && this.possibleDataTypes().includes(selectedDataType) ? selectedDataType : this.selectedDataType;
    }

    entityType = (): EntityType => EntityType.meter

    refresh = () => this.sendQuery("getMeasurementsData", this.filters.timeRange)
        .subscribe((d: ChartDataPerMeasurement) => this.setData(d));

    possibleDataTypes = (): DataType[] =>
        [DataType.electricityIntermediateConsumption, DataType.electricityGrossProduction, DataType.electricityGrossProductionReactive,
            DataType.electricityConsumption, DataType.electricityFeedIn]
            .concat([DataType.gasConsumption, DataType.heatConsumption, DataType.waterConsumption,
                DataType.waterIntermediateConsumption,DataType.chargePointConsumption, DataType.chargePointIntermediateConsumption])

    createData = (result: ChartDataPerMeasurement): Observable<PerformanceRecord[]> => {
        return this.sendQuery("getChartDataQueryFilters").pipe(mergeMap((d: DataQueryFilters) =>
            this.getEntities().pipe(map(meters => this.filterMetersBasedOnSelectedDataType(meters)),
                map(meters => {
                    const filteredData = result?.byEntity?.map(
                        m => new Measurements(m, this.getSelectedDataType, d.timeRange)) || [];
                    return Object.entries(lodash.groupBy(filteredData, d => d.data.entityId))
                        .map(([entityId, dataset]) => ({
                            meter: meters.find(l => l.meter.meterId === entityId),
                            dataset,
                        }))
                        .filter(e => e.meter)
                        .map(e => {
                            const area = RefdataUtils.getMeterArea(e.meter.meter);
                            const value = lodash.sum(e.dataset.flatMap(d => d.groupMeasurements()));
                            return {
                                entityId: e.meter.meter.meterId,
                                value: this.showPerSquareMeter() ? (area ? lodash.round(value / area, 1) : null) : value,
                                estimatedValue: null,
                                label: RefdataUtils.meterFormatter(e.meter),
                                parentId: e.meter.meter.parentId,
                                virtual: e.meter.meter.info.virtual,
                                type: e.meter.meter.info.type || e.meter.meter.details?.type}
                        });
                }))));
    }

    private getEntities = (): Observable<Entity[]> => {
        return this.sendQuery("getChartDataQueryFilters").pipe(mergeMap((q: DataQueryFilters) => {
            const selectedEntities: Entity[] = q.sources?.flatMap(s => s.source.getMeters()) || [];
            return selectedEntities.length > 0 ? of(selectedEntities) : this.getAllEntities();
        }))
    }

    getAllEntities = (): Observable<Entity[]> => sendQuery("getMetersAsEntities");

    filterMetersBasedOnSelectedDataType = (selection: Entity[]) => {
        const entities = selection.filter(s => none(selection, (e) => e.getParentAsEntity().getEntityId() === s.getEntityId()));
        switch (this.getSelectedDataType) {
            case DataType.electricityConsumption:
            case DataType.electricityConsumptionOffPeak:
            case DataType.electricityFeedIn:
            case DataType.electricityFeedInOffPeak:
                return entities.filter(e => e.meter && e.meter.info.type == "PRIMARY" && e.connection?.info.connectionType === "Electricity");
            case DataType.electricityIntermediateConsumption:
            case DataType.electricityIntermediateConsumptionReactive:
                return entities.filter(e => e.meter && e.meter.info.type == "INTERMEDIATE" && e.connection?.info.connectionType === "Electricity");
            case DataType.electricityGrossProductionReactive:
            case DataType.electricityGrossProduction:
                return entities.filter(e => e.meter && (e.meter.info?.type == "GROSS_PRODUCTION" || e.meter.details?.type == "GROSS_PRODUCTION") && e.connection?.info.connectionType === "Electricity");
            case DataType.gasConsumption:
                return entities.filter(e => e.meter && e.connection?.info.connectionType === "Gas");
            case DataType.heatConsumption:
                return entities.filter(e => e.meter && e.connection?.info.connectionType === "Heat");
            case DataType.waterIntermediateConsumption:
                return entities.filter(e => e.meter && e.meter.info.type !== "PRIMARY" && e.connection?.info.connectionType === "Water");
            case DataType.waterConsumption:
                return entities.filter(e => e.meter && e.meter.info.type !== "INTERMEDIATE" && e.connection?.info.connectionType === "Water");
            case DataType.chargePointConsumption:
                return entities.filter(e => e.meter && e.meter.info.type !== "INTERMEDIATE" && e.connection?.info.connectionType === "ChargePoint");
            case DataType.chargePointIntermediateConsumption:
                return entities.filter(e => e.meter && e.meter.info.type === "INTERMEDIATE" && e.connection?.info.connectionType === "ChargePoint");
            default:
                return entities;
        }
    }

    navigateToEntity = (event: ChartEvent, elements: ActiveElement[], chart: Chart) => {
        const points = chart.getElementsAtEventForMode(event.native, 'nearest', {intersect: true}, true);

        if (points.length > 0) {
            const clickedRecord: PerformanceRecord = this.currentPageRecords[points[0].index];
            if (clickedRecord.entityId) {
                if (clickedRecord.type === MeterType.INTERMEDIATE) {
                    this.options.groupByEntityId = true;
                }
                this.sendCommandAndForget("selectEntity", clickedRecord.entityId);
            }
        }
    }

    onHover = (event: ChartEvent, elements: ActiveElement[], chart: Chart) => {
        const target: any = event.native.target;
        target.style.cursor = elements.length > 0 ? "pointer" : "auto";
    }
}
