import {Component, Input} from '@angular/core';
import {View} from "src/app/common/view";
import {Handler} from "src/app/common/handler";
import {AppContext} from "../../../../../app-context";
import {Entity} from "../../../../../handlers/entity";
import {DeleteLocation, Location} from "@flowmaps/flowmaps-typescriptmodels";
import {AppCommonUtils} from "../../../../../common/app-common-utils";
import {DashboardContext} from "../../../../dashboard/dashboard.context";
import {RefdataUtils} from "../../../refdata-utils";
import {LocationComponentData, LocationDetailsComponent} from "../../location-details/location-details.component";
import {
    ConnectionComponentData,
    ConnectionDetailsComponent
} from "../../../connections/connection-details/connection-details.component";
import {MoveLocationModalComponent} from "../../move-location-modal/move-location-modal.component";

@Component({
    selector: 'app-location-overview-item',
    templateUrl: './location-overview-item.component.html',
    styleUrls: ['./location-overview-item.component.scss']
})
@Handler()
export class LocationOverviewItemComponent extends View {
    appContext = AppContext;
    @Input() entity: Entity;

    get location(): Location {
        return this.entity.location;
    }

    sortedConnectionsForLocation = () => this.entity.location.connections.sort(RefdataUtils.connectionsComparator.compare)
        .map(c => new Entity(this.entity.organisation, this.entity.location, c));

    trackByForConnection = (index: number, record: Entity) => record.connection.connectionId;

    openLocationGraph = () => AppCommonUtils.navigateToUrl('/dashboard/' + DashboardContext.dashboardToBase64(DashboardContext.locationDashboard(this.location.locationId)));

    openLocationDetails = () => this.openModal(LocationDetailsComponent, <LocationComponentData>{
        entity: this.entity
    });

    deleteLocation = () => this.sendCommand("com.flowmaps.api.organisation.DeleteLocation", <DeleteLocation>{
        organisationId: this.entity.organisation.organisationId,
        locationId: this.location.locationId
    });

    openConnectionDetails = (connection: Entity) => this.openModal(ConnectionDetailsComponent, <ConnectionComponentData>{
        organisation: this.entity.organisation,
        location: this.location,
        connection: connection?.connection
    });

    hasActiveMeters(location: any): boolean {
        if (!location || !location.connections) {
            return false;
        }
        return location.connections.some((connection: any) => connection.meters && connection.meters.length > 0);
    }

    openMoveLocationModal(entity: Entity) {
        this.openModal(MoveLocationModalComponent,  <LocationComponentData>{
           entity: entity
        },{
            cssClass: 'custom-modal-dialog'
        })
    }
}
