import {Component, Input, TemplateRef, ViewChild} from '@angular/core';
import {View} from "src/app/common/view";
import {Handler} from "src/app/common/handler";
import {AlertEntity} from "../../../../handlers/issues-standalone-handler";
import {Alert, AlertStatus, AlertType, MuteAlert, ResolveAlert, UnmuteAlert} from "@flowmaps/flowmaps-typescriptmodels";
import {RefdataUtils} from "../../../refdata/refdata-utils";
import {AppContext} from "../../../../app-context";
import moment from "moment";
import {openConfirmationModalWithCallback} from "../../../../app-utils";
import {
    ModalConfirmAutofocus,
    ModalConfirmAutofocusData
} from "../../../../common/modal-confirm/modal-confirm.component";
import {Dashboard} from "../../../dashboard/dashboard.types";
import {DashboardContext} from "../../../dashboard/dashboard.context";

@Component({
    selector: 'app-alert-overview-item',
    templateUrl: './alert-overview-item.component.html',
    styleUrls: ['./alert-overview-item.component.scss']
})
@Handler()
export class AlertOverviewItemComponent extends View {
    appContext = AppContext;
    refDataUtils = RefdataUtils;
    @Input() alertEntity: AlertEntity;
    @Input() selecting: boolean;

    muteDate: string;

    @ViewChild("muteAlertTemplate") muteAlertTemplate: TemplateRef<any>;

    get alert(): Alert {
        return this.alertEntity.alert;
    }

    tomorrow = (): string => moment().startOf("day").add(1, "day").toISOString();
    formatTimestamp = (date: string) => moment(date).format(AppContext.displayFormat);

    isResolved = (): boolean => AlertStatus.RESOLVED == this.alert.alertStatus;
    isMuted = (): boolean => AlertStatus.MUTED == this.alert.alertStatus;

    toggleSelectedItem = (value: boolean) => this.sendCommandAndForget("selectionChange", {
        entity: this.alertEntity,
        selected: value
    });

    getAlertStatusConfig = (): AlertStatusConfig => {
        switch (this.alert.alertStatus) {
            case AlertStatus.RESOLVED:
                return {
                    colorClass: "success",
                    icon: "bi-check-circle"
                };
            case AlertStatus.MUTED:
                return {
                    colorClass: "muted",
                    icon: "bi-volume-mute"
                };
            default:
                return {
                    colorClass: this.badgeColorForAlertType(),
                    icon: "bi-record-circle"
                };
        }
    }

    badgeColorForAlertType = () => {
        if (this.alert.alertStatus === AlertStatus.RESOLVED) {
            return "light";
        }
        switch (this.alert.details.type) {
            case AlertType.Disconnected:
                return "danger";
            case AlertType.MissingData:
                return "warning";
            case AlertType.ContractedPowerExceeded:
                return "dark";
            case AlertType.Peak:
                return "secondary";
            default:
                return "info";
        }
    }

    getLocationDashboardLink = (): string => {
        const locationDashboard: Dashboard = DashboardContext.defaultDashboard();
        locationDashboard.info.sources.locationIds = [this.alertEntity.entity.location.locationId];
        return `/dashboard/${DashboardContext.dashboardToBase64(locationDashboard)}`;
    }

    openResolveAlertModal = () => openConfirmationModalWithCallback((confirmed) => {
        if (confirmed) {
            this.sendCommand("com.flowmaps.api.monitoring.alerting.ResolveAlert", <ResolveAlert>{
                alertId: this.alert.alertId
            }, () => this.alertEntity.alert.alertStatus = AlertStatus.RESOLVED);
        }
    }, ModalConfirmAutofocus, <ModalConfirmAutofocusData>{
        type: "info",
        title: "Resolve alert",
        message: "Are you sure you want to resolve this alert?",
        confirmText: "Resolve alert",
        cancelText: "Cancel"
    }, 'static')

    openMuteAlertModal = () => {
        this.muteDate = this.alert.muteDeadline;
        openConfirmationModalWithCallback((confirmed) => {
            if (confirmed) {
                this.sendCommand("com.flowmaps.api.monitoring.alerting.MuteAlert", <MuteAlert>{
                    alertId: this.alert.alertId,
                    deadline: this.muteDate
                }, () => {
                    this.alertEntity.alert.alertStatus = AlertStatus.MUTED;
                    this.alertEntity.alert.muteDeadline = this.muteDate;
                    this.muteDate = null;
                });
            }
        }, ModalConfirmAutofocus, <ModalConfirmAutofocusData>{
            type: "info",
            title: "Mute alert",
            body: this.muteAlertTemplate,
            confirmText: "Mute alert",
            cancelText: "Cancel"
        }, 'static');
    }

    openUnmuteAlertModal = () => this.sendCommand("com.flowmaps.api.monitoring.alerting.UnmuteAlert", <UnmuteAlert>{
        alertId: this.alert.alertId
    }, () => {
        this.alertEntity.alert.alertStatus = AlertStatus.OPEN;
        this.alertEntity.alert.muteDeadline = null;
    })
}

interface AlertStatusConfig {
    colorClass: string;
    icon: string;
}