import {AfterViewInit, Component, TemplateRef, ViewChild} from '@angular/core';
import {View} from "src/app/common/view";
import {Handler} from "src/app/common/handler";
import {AppContext} from "../../../app-context";
import {map, Observable} from "rxjs";
import {FacetFilter, FacetResult, GetMyAlertsNew, Organisation} from "@flowmaps/flowmaps-typescriptmodels";
import {filterByTermArray, removeItem, sort} from "../../../common/utils";
import {RefdataUtils} from "../../refdata/refdata-utils";
import {ComparatorChain} from "../../../common/comparator-chain";
import {DateFieldRange} from "../../../common/date/date-range/date-field-range";
import {FacetUtils} from "../../../common/facets/facet-utils";
import {sendFacetsQuery} from "../../../common/app-common-utils";
import {AlertEntity} from "../../../handlers/issues-standalone-handler";
import {HandleCommand} from "../../../common/handle-command";
import {AddAlertComponent} from "../add-alert/add-alert.component";
import {SentenceCasePipe} from "../../../common/sentence-case.pipe";
import {Entity} from "../../../handlers/entity";

@Component({
    selector: 'app-alerts-overview',
    templateUrl: './alerts-overview.component.html',
    styleUrls: ['./alerts-overview.component.scss']
})
@Handler()
export class AlertsOverviewComponent extends View implements AfterViewInit {
    facetUtils = FacetUtils;
    comparator: ComparatorChain = new ComparatorChain('alertStatusPriority', '-alert.timestamp',
        'entity.connection.info.code', 'alert.alertId');
    appContext = AppContext;

    term: string;
    query: Observable<AlertEntity[]>;
    data: AlertEntity[] = [];

    hiddenFacets: string[] = ["entityIds"];
    facets: Observable<FacetResult>;
    facetFilters: FacetFilter[];
    defaultFacetFilters: FacetFilter[] = [{
        facetName: "alertStatus",
        values: ["OPEN"]
    }];
    @ViewChild("filtersInModal") filtersTemplate: TemplateRef<any>;

    dateRange: DateFieldRange;

    selecting: boolean;
    selectedItems: AlertEntity[] = [];
    organisations: Organisation[] = [];

    ngAfterViewInit() {
        this.subscribeTo("getOrganisations").subscribe(o => this.organisations = o);
        this.executeQuery();
    }

    executeQuery = () => {
        const query: GetMyAlertsNew = {facetFilters: this.facetFilters, timeRange: this.dateRange};
        this.query = this.subscribeTo("getAlerts", query)
            .pipe(map((o: AlertEntity[]) => o.filter(filterByTermArray([this.term], Entity.getExcludedFilterFields()))))
            .pipe(map(c => sort(c, this.comparator)));
        this.facets = sendFacetsQuery("com.flowmaps.api.monitoring.alerting.GetMyAlertsNew", query, {caching: false})
            .pipe(map(f => FacetUtils.addZeroFacetResults(f, "alertStatus", "OPEN", "RESOLVED", "MUTED")));
    }

    trackByForRecord = (index: number, record: AlertEntity) => record.alert.alertId;

    openFiltersInModal = () => this.openModal(this.filtersTemplate, null);

    addAlert = () => this.openModal(AddAlertComponent);

    facetNameFormatter = (name: string): string => {
        switch (name) {
            case "alertStatus":
                return "Alert status";
            case "type":
                return "Alert type";
            case "organisationId":
                return "Organisation";
            case "surveyor":
                return "Data source";
            default:
                return SentenceCasePipe.formatWithSpaces(name);
        }
    }

    getTranslateValues = (name: string): boolean => {
        switch (name) {
            case "organisationId":
            case "entityIds":
            case "message":
            case "surveyor":
                return false;
            default:
                return true;
        }
    }

    getFacetValueFormatter = (name: string): (value: string) => string => {
        switch (name) {
            case "alertStatus":
                return v => RefdataUtils.enumFormatter(v);
            case "organisationId":
                return v => RefdataUtils.organisationFormatter(this.organisations.find(o => o.organisationId === v));
        }
    }

    allItemsSelected = (): boolean => this.selectedItems.length === this.data.length;

    toggleSelectAll = (): void => {
        const allSelected = this.allItemsSelected();
        this.data.forEach(d => this.selectionChange({
            entity: d,
            selected: !allSelected
        }));
    }

    toggleSelection = () => {
        this.selecting = !this.selecting;
        if (!this.selecting) {
            this.selectedItems.forEach(c => delete c['selected']);
            this.selectedItems = [];
        }
    }

    @HandleCommand("selectionChange")
    selectionChange(record: { entity: AlertEntity, selected: boolean }) {
        if (record.selected) {
            if (!this.selectedItems.includes(record.entity)) {
                this.selectedItems.push(record.entity);
            }
        } else {
            this.selectedItems = removeItem(this.selectedItems, record.entity);
        }
        record.entity['selected'] = record.selected;
    }
}
