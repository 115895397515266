import {NgModule} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, Route, RouterModule} from '@angular/router';
import {SignInComponent} from './views/signed-out/sign-in/sign-in.component';
import {ForgotPasswordComponent} from './views/signed-out/forgot-password/forgot-password.component';
import {SignedOutComponent} from './views/signed-out/signed-out.component';
import {HomeComponent} from './views/home/home.component';
import {EditProfileComponent} from './views/edit-profile/edit-profile.component';
import {EditProfileSecurityComponent} from './views/edit-profile/edit-profile-security/edit-profile-security.component';
import {EditProfileGeneralComponent} from './views/edit-profile/edit-profile-general/edit-profile-general.component';
import {
    OrganisationsOverviewComponent
} from "./views/refdata/organisations/organisations-overview/organisations-overview.component";
import {ConfirmUserComponent} from "./views/signed-out/confirm-user/confirm-user.component";
import {ResetPasswordComponent} from "./views/signed-out/reset-password/reset-password.component";
import {
    OrganisationRoutingComponent
} from "./views/refdata/organisations/organisation-routing/organisation-routing.component";
import {
    OrganisationDetailsComponent
} from "./views/refdata/organisations/organisation-details/organisation-details.component";
import {Observable} from "rxjs";
import {LocationsOverviewComponent} from "./views/refdata/locations/locations-overview/locations-overview.component";
import {
    ConnectionsOverviewComponent
} from "./views/refdata/connections/connections-overview/connections-overview.component";
import {UsersOverviewComponent} from "./views/refdata/users/users-overview/users-overview.component";
import {TaxesOverviewComponent} from "./views/refdata/taxes/taxes-overview/taxes-overview.component";
import {ContractsOverviewComponent} from "./views/refdata/contracts/contracts-overview/contracts-overview.component";
import {ContractRoutingComponent} from "./views/refdata/contracts/contract-routing/contract-routing.component";
import {ContractDetailsComponent} from "./views/refdata/contracts/contract-details/contract-details.component";
import {IssuesOverviewComponent} from "./views/issues/issues-overview/issues-overview.component";
import {AlertsOverviewComponent} from "./views/alerts/alerts-overview/alerts-overview.component";
import {DashboardComponent} from "./views/dashboard/dashboard.component";
import {ReportComponent} from "./views/reports/report/report.component";
import {ReportRunnerComponent} from "./views/reports/report-runner/report-runner.component";
import {ApiKeysOverviewComponent} from "./views/edit-profile/api-keys/api-keys-overview/api-keys-overview.component";

const routes: ExtendedRoute[] = [
    {
        path: '', component: HomeComponent,
        children: [
            {path: '', component: DashboardComponent},
            {path: 'dashboard/:dashboard', component: DashboardComponent},
            {
                path: 'profile', component: EditProfileComponent, children: [
                    {path: "", component: EditProfileGeneralComponent},
                    {path: "settings", component: EditProfileSecurityComponent},
                    {path: "api-keys", component: ApiKeysOverviewComponent}
                ]
            },
            {path: "report/:reportId", component: ReportComponent},
            {path: "reporting/run", component: ReportRunnerComponent},
            {path: 'organisations', component: OrganisationsOverviewComponent},
            {
                path: 'organisation/:organisationId',
                component: OrganisationRoutingComponent,
                children: [
                    {path: 'details', component: OrganisationDetailsComponent, data: {activeTabIndex: 0}},
                    {path: 'authorisations', component: OrganisationDetailsComponent, data: {activeTabIndex: 1}},
                    {path: 'locations', component: OrganisationDetailsComponent, data: {activeTabIndex: 2}},
                    {path: 'connections', component: OrganisationDetailsComponent, data: {activeTabIndex: 3}},
                    {path: '**', redirectTo: 'details'},
                ]
            },
            {path: 'locations', component: LocationsOverviewComponent},
            {path: 'connections', component: ConnectionsOverviewComponent},
            {path: 'users', component: UsersOverviewComponent},
            {path: 'alerts', component: AlertsOverviewComponent},
            {path: 'issues', component: IssuesOverviewComponent},
            {path: 'errors', component: IssuesOverviewComponent},
            {path: 'contracts', component: ContractsOverviewComponent},
            {path: 'contract/:contractId', component: ContractRoutingComponent,
                children: [
                    { path: 'details', component: ContractDetailsComponent, data: {activeTabIndex: 0} },
                    { path: 'periods', component: ContractDetailsComponent, data: {activeTabIndex: 1} },
                    { path: 'links', component: ContractDetailsComponent, data: {activeTabIndex: 2} },
                    { path: '**', redirectTo: 'details' }
                ]},
            {path: 'taxes', component: TaxesOverviewComponent},
        ]
    },
    {
        path: '', component: SignedOutComponent, children: [
            {path: 'login', component: SignInComponent},
            {path: 'forgot-password', component: ForgotPasswordComponent},
            {path: 'reset-password', component: ResetPasswordComponent},
            {path: 'confirm', component: ConfirmUserComponent},
            {path: 'confirm/:token', component: ConfirmUserComponent},
            {path: '**', redirectTo: ''},
        ]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}


export interface ExtendedRoute extends Route {
    data?: RouteData;
    children?: ExtendedRoute[];
}

export interface RouteData {
    breadcrumbLabel?: (routeParams: any) => Observable<string>;
    activeTabIndex?: number;
    reUseRoute?: boolean;
}

export interface ExtendedActivatedRoute extends ActivatedRoute {
    data: Observable<RouteData>;
    snapshot: ExtendedActivatedRouteSnapshot;
}

export interface ExtendedActivatedRouteSnapshot extends ActivatedRouteSnapshot {
    data: RouteData;
}
