import {Component, OnInit} from '@angular/core';
import {BaseMeasurementChartComponent} from "../base-measurement-chart";
import {ChartOptionType} from "../../dashboard/dashboard.types";
import {ConnectionType, DataType} from "@flowmaps/flowmaps-typescriptmodels";

@Component({
    selector: 'app-chargepoint-chart',
    templateUrl: './chargepoint-chart.component.html',
    styleUrls: ['./chargepoint-chart.component.scss']
})
export class ChargepointChartComponent extends BaseMeasurementChartComponent implements OnInit {
    chartOptionType: ChartOptionType = ChartOptionType.ChargePoint;
    mainConsumptionType: DataType = DataType.chargePointConsumption;
    mainConnectionType: ConnectionType = ConnectionType.ChargePoint;
    connectionTypes: ConnectionType[] = [ConnectionType.ChargePoint];
    contractedCapacityRequiredForPower: boolean = false;
    showConsumption = (): boolean => true;

    openModal() {
        super.openModal(ChargepointChartComponent);
    }

    measurementTypes = (): DataType[] => {
        let baseMeasurements = this.showConsumption() ? [DataType.chargePointConsumption] : [];
        if (this.groupByEntityIdEnabled() || this.onlyMetersSelected) {
            baseMeasurements = baseMeasurements.concat([DataType.chargePointIntermediateConsumption]);
        }
        return baseMeasurements;
    };

    measurementIntermediateLink = (): Map<DataType, DataType[]> => {
        const intermediateValues = [DataType.chargePointIntermediateConsumption]
        return new Map<DataType, DataType[]>()
            .set(DataType.chargePointConsumption, intermediateValues)
    };

    measurementTypesMapped = (): {[key: string]: DataType[]} => {
        return {
            [DataType.chargePointConsumption]: [DataType.chargePointConsumption],
            [DataType.chargePointIntermediateConsumption]: [DataType.chargePointIntermediateConsumption],
        };
    }

    protected getYAxis() {
        return this.options.splitOffPeak ? "y1" : "y1Aggregated";
    }
}

