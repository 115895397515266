import {EventEmitter} from "@angular/core";
import {MeasurementData, MeasurementDataset} from "../views/charts/base-measurement-chart";
import {DateFieldRange} from "../common/date/date-range/date-field-range";
import {
    ContractMeasurementResult,
    DataType,
    DayOfWeekMeasurementsResult,
    MeasurementsResult,
    TimeResolution
} from "@flowmaps/flowmaps-typescriptmodels";
import {CompletenessInfo} from "../app-context";
import {DateTimeSlot} from "../views/dashboard/dashboard.context";

export class ChartDataProvider {
    lastValue: MeasurementData = {
        datasets: []
    };
    aggregatedDataChanged = new EventEmitter<MeasurementData>();

    emit(value: MeasurementData) {
        this.aggregatedDataChanged.emit(this.lastValue = value);
    }
}

export interface DashboardTime extends DateFieldRange {
    resolution?: TimeResolution;
}

export interface TimeChangedEvent {
    timeRange: DashboardTime;
    historyType?: 'add' | 'remove';
}

export interface DashboardData {
    labels: string[];
    currentData?: ChartDataPerMeasurement;
    previousData?: ChartDataPerMeasurement;
}

export interface ChartDataPerMeasurement {
    totals?: MeasurementsResult[];
    byEntity?: MeasurementsResult[];
    measurements?: { [P in DataType]?: MeasurementDataset[] };
    estimatedMeasurements?: { [P in DataType]?: MeasurementDataset[] };
    totalsPerYear?: MeasurementsResult;
    byDayOfWeek?: DayOfWeekMeasurementsResult;
    completeness?: CompletenessInfo[];
    contractMeasurements?: ContractMeasurementResult;
}

export interface Slot {
    startTime: number;
    endTime: number;
    label: string;
    tableHeader: string;
    values: number[];
}

interface TimeSlots {
    [key: string]: {
        unit: DateTimeSlot;
        amount: number;
    }
}

export interface GroupedData {
    labels: string[];
    data: number[];
}

export interface ChartSimpleData {
    x: number;
    y: number;
}