import {AggregatedDataPoint, DataType, MeasurementsResult} from "@flowmaps/flowmaps-typescriptmodels";
import {DashboardTime, Slot} from "../../utils/chart-data-provider";
import {AppContext} from "../../app-context";
import moment from "moment/moment";
import lodash, {cloneDeep} from "lodash";
import {DashboardContext, DateTimeSlot} from "../dashboard/dashboard.context";
import timeslots from "../../resources/timeslots.json";

export class Measurements {
    private static timeSlots = timeslots as TimeSlots;
    data: MeasurementsResult;

    constructor(data: MeasurementsResult, private measurementType: DataType, private dateRange: DashboardTime) {
        this.data = data;
    }

    groupMeasurements = () => Measurements.groupByTimeFrames(Measurements.dataPointsToChartData(Object.entries(this.data.measurements)
                    .filter(([key, value]) => this.filterMeasurements(key as DataType))
                    .flatMap(([key, value]) => value)
                    .filter(a => a)),
            this.dateRange, AppContext.getAggregationMethod(this.measurementType)).data;

    private filterMeasurements(type: DataType): boolean {
        const groupedTypes: { [key: string]: DataType[] } = {
            electricityConsumption: [DataType.electricityConsumption, DataType.electricityConsumptionOffPeak],
            electricityFeedIn: [DataType.electricityFeedIn, DataType.electricityFeedInOffPeak],
            electricityConsumptionReactive: [DataType.electricityConsumptionReactive, DataType.electricityConsumptionReactiveOffPeak],
            electricityFeedInReactive: [DataType.electricityFeedInReactive, DataType.electricityFeedInReactiveOffPeak]
        };
        const mainGroup = Object.entries(groupedTypes).find(([_, types]) =>
            types.includes(this.measurementType)
        );
        return mainGroup ? groupedTypes[mainGroup[0]].includes(type) : type === this.measurementType;
    }

    static appendAggregatedDataToSlots(data: AggregatedDataPoint[], slots: Slot[]): Slot[] {
        return this.appendDataToSlots(this.dataPointsToChartData(data), slots);
    }

    static groupByTimeFrames(data: ChartSimpleData[], dateRange: DashboardTime, aggregationMethod: (data: number[]) => number = lodash.sum): GroupedData {
        const slots = Measurements.getSlots(dateRange);
        return {
            labels: slots.map(s => s.label),
            data: this.appendDataToSlots(data, slots).map(e => aggregationMethod(e.values))
        };
    }

    static getSlots(dateRange: DashboardTime) {
        const startDate = moment(dateRange.start);
        const endDate = moment(dateRange.end);
        const foundSlot = Measurements.timeSlots[dateRange.resolution];
        return lodash.range(0, endDate.diff(startDate, foundSlot.unit, false), foundSlot.amount).map(() => {
            const startTime = startDate.valueOf();
            const label = startDate.clone().local().locale(AppContext.getPreferredLanguage()).format(DashboardContext.getFormat(foundSlot.unit));
            const header = startDate.clone().local().locale(AppContext.getPreferredLanguage()).format(DashboardContext.getTableHeaderFormat(foundSlot.unit));
            const endTime = startDate.add(foundSlot.amount, foundSlot.unit).valueOf();

            return <Slot>{
                startTime: startTime,
                endTime: endTime,
                label: label,
                tableHeader: header,
                values: []
            }
        });
    }

    private static appendDataToSlots(data: ChartSimpleData[], slots: Slot[]): Slot[] {
        const s = cloneDeep(slots);
        data.forEach(r => {
            const index = s.findIndex(s => lodash.inRange(r.x, s.startTime, s.endTime));
            if (index > -1) {
                s[index].values.push(r.y);
            }
        });
        return s;
    }

    static dataPointsToChartData(data: AggregatedDataPoint[]): ChartSimpleData[] {
        return data ? data.map(r => ({
            x: moment(r.timeRange.start).valueOf(),
            y: r.value
        })) : [];
    }
}

interface TimeSlots {
    [key: string]: {
        unit: DateTimeSlot;
        amount: number;
    }
}

interface GroupedData {
    labels: string[];
    data: number[];
}

interface ChartSimpleData {
    x: number;
    y: number;
}