import {Injectable} from "@angular/core";
import {GetMyMeterViews, MeterType, MeterView, SourceIds} from "@flowmaps/flowmaps-typescriptmodels";
import {map, mergeMap, Observable} from "rxjs";
import {Entity} from "./entity";
import lodash from "lodash";
import {StandaloneHandler} from "../common/standalone-handler";
import {HandleQuery} from "../common/handle-query";
import {sendQuery, subscribeTo} from "../common/app-common-utils";

@Injectable({
    providedIn: "root"
})
export class MeterViewsStandaloneHandler extends StandaloneHandler {

    @HandleQuery("getMeterViews")
    getMeterViews(sources: SourceIds): Observable<MeterViewEntity[]> {
        return subscribeTo("com.flowmaps.api.monitoring.GetMyMeterViews", <GetMyMeterViews>{
            sources: sources
        }).pipe(mergeMap((m: MeterView[]) => sendQuery("getEntitiesById", m.map(m => m.meterId))
            .pipe(map((e: Entity[]) => this.asMeterViewEntity(m, e)))));
    }

    @HandleQuery("getPrimaryMeterViews")
    getPrimaryMeterViews(sources: SourceIds): Observable<MeterViewEntity[]> {
        return subscribeTo("getMeterViews", sources)
            .pipe(map((meterViews: MeterViewEntity[]) => meterViews.filter(v => (!!sources.meterIds?.length
                    && [MeterType.INTERMEDIATE, MeterType.GROSS_PRODUCTION].includes(v.meterView.meterType))
                || v.meterView.meterType === MeterType.PRIMARY)));
    }

    private asMeterViewEntity = (meterViews: MeterView[], entities: Entity[]): MeterViewEntity[] => {
        return meterViews.map(m => {
            const entity = entities.find(e => e.meter?.meterId === m.meterId);
            const meterViewEntity: MeterViewEntity = {
                meterView: m,
                entity: entity
            }
            meterViewEntity.meterView.dataPeriods = meterViewEntity.meterView.dataPeriods.map(d => ({
                start: new Date(d.start),
                end: new Date(d.end)
            }));
            if (entity && entity.meter) {
                meterViewEntity.desiredStartDate = entity.connection.info.desiredStartDate ? new Date(entity.connection.info.desiredStartDate) : null;
                const endDate = lodash.min([entity.meter.timeRange?.end, entity.connection.info.desiredEndDate].filter(a => a));
                meterViewEntity.activeRange = {
                    start: entity.meter.timeRange?.start ? new Date(entity.meter.timeRange.start) : null,
                    end: endDate ? new Date(endDate) : null
                }
                meterViewEntity.authorizedRange = {
                    start: entity.meter.info?.authorizedFrom ? new Date(entity.meter.info.authorizedFrom) : null,
                    end: entity.meter.info?.authorizedUntil ? new Date(entity.meter.info.authorizedUntil) : null
                }
            }
            return meterViewEntity;
        })
    }
}

export interface MeterViewEntity {
    meterView: MeterView;
    entity: Entity;
    activeRange?: DateRange;
    authorizedRange?: DateRange;
    desiredStartDate?: Date;
}

interface DateRange {
    start: Date;
    end: Date;
}