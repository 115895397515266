import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TreeViewItem} from "../../../utils/source-providers/sources-provider";
import {OrganisationProvider} from "../../../utils/source-providers/organisation-provider";
import {DashboardContext} from "../../dashboard/dashboard.context";

@Component({
    selector: 'app-dashboard-sources-list',
    templateUrl: './dashboard-sources-list.component.html',
    styleUrls: ['./dashboard-sources-list.component.scss'],
})
export class DashboardSourcesListComponent {
    context = DashboardContext;

    @Input() data: TreeViewItem[];

    @Input() depth: number = 0;
    @Input() recordCls: string = "";
    @Input() filterTerm: string;

    @Input() sourcesProvider: OrganisationProvider;

    @Output() selectionChange: EventEmitter<TreeViewItem> = new EventEmitter<TreeViewItem>();

    trackByTreeViewItemId = (index: number, treeViewItem: TreeViewItem) => treeViewItem.info.id;
}