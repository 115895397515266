import {Component, OnInit} from '@angular/core';
import moment, {unitOfTime} from "moment";
import {DashboardContext} from "../dashboard.context";
import {DashboardTime, TimeChangedEvent} from "../../../utils/chart-data-provider";
import {AppContext} from "../../../app-context";
import {DateFieldRange, MomentDateFieldRange} from "../../../common/date/date-range/date-field-range";
import {TranslateDirective} from "../../../common/utils/translate.directive";
import {Handler} from "../../../common/handler";
import {View} from "../../../common/view";
import {DatePickerRange} from "../../../common/date/date-picker/date-picker.component";
import {DateRangeUtils} from "../../../common/date/date-range/date-range.utils";
import {localTimeFormat, lodash} from "../../../common/utils";
import {TimeResolution} from "@flowmaps/flowmaps-typescriptmodels";

@Component({
  selector: 'app-data-provider-date-range',
  templateUrl: './data-provider-date-range.component.html',
  styleUrls: ['./data-provider-date-range.component.scss']
})
@Handler()
export class DataProviderDateRangeComponent extends View implements OnInit {
  protected readonly localTimeFormat = localTimeFormat;

  context = DashboardContext;
  timeRange: DateFieldRange;
  ranges: MomentDateFieldRange[];

  ngOnInit() {
    this.subscribeTo("getTimeRange").subscribe((d: DashboardTime) => this.timeRange = d);
    this.sendQuery("getTimeRanges").subscribe(r => this.ranges = r);
  }

  movePeriodBack = () => {
    this.dateRangeChange(this.getPreviousDateTimeRange());
  }

  movePeriodForward = () => {
    this.dateRangeChange(this.getNextDateTimeRange());
  }

  dateRangeChange(dateTimeRange: DateFieldRange) {
    this.timeRange = dateTimeRange;
    this.publishEvent("timeChanged", <TimeChangedEvent> {
      timeRange: {
        resolution: AppContext.resolutionForTimeRange(dateTimeRange),
        ...dateTimeRange
      }
    });
  }

  get dateLabel(): string {
    if (!this.timeRange) {
      return "";
    }
    const timeRange: MomentDateFieldRange = {
      start: moment(this.timeRange.start),
      end: moment(this.timeRange.end).subtract(1, "day"),
      label: this.timeRange.label
    };
    if (timeRange.label) {
      return TranslateDirective.getTranslation(timeRange.label, true);
    }
    const isSame = {
      day: timeRange.start.isSame(timeRange.end, 'day'),
      month: timeRange.start.isSame(timeRange.end, 'month'),
      wholeMonth: timeRange.start.isSame(timeRange.end, 'month') && this.isSame(timeRange, 'month'),
      year: timeRange.start.isSame(timeRange.end, 'year'),
      wholeYear: timeRange.start.isSame(timeRange.end, 'year') && this.isSame(timeRange, 'year')
    };
    if (isSame.day && isSame.month && isSame.year) {
      return moment(this.timeRange.start).format("DD MMM YYYY");
    }
    if (isSame.month && isSame.wholeMonth && isSame.year) {
      return moment(this.timeRange.start).format("MMMM YYYY");
    }
    if (isSame.wholeYear) {
      return moment(this.timeRange.start).format("YYYY");
    }
    return `${timeRange.start.format(AppContext.displayFormat)} - ${timeRange.end.format(AppContext.displayFormat)}`;
  }

  private isSame(timeRange: MomentDateFieldRange, unitOfTime: unitOfTime.StartOf) {
    if (unitOfTime === 'year' && !this.isSame(timeRange, 'month')) {
      return false;
    }
    return timeRange.start.isSame(timeRange.start.clone().startOf(unitOfTime), 'day')
        && timeRange.end.isSame(timeRange.end.clone().endOf(unitOfTime), 'day');
  }

  private getPreviousDateTimeRange(): DateFieldRange {
    let {time, resolution, difference} = this.calculateDifference();
    const range: DatePickerRange = {
      start: time.start.clone().subtract(difference, resolution),
      end: time.start
    };
    const label = DateRangeUtils.getRangeLabel(range, this.ranges);
    return {
      start: range.start.format(localTimeFormat),
      end: range.end.format(localTimeFormat),
      label: label
    }
  }

  private getNextDateTimeRange(): DateFieldRange {
    let {time, resolution, difference} = this.calculateDifference();
    const range: DatePickerRange = {
      start: time.end.clone(),
      end: time.end.clone().add(difference, resolution)
    }
    const label = DateRangeUtils.getRangeLabel(range, this.ranges);
    return {
      start: range.start.format(localTimeFormat),
      end: range.end.format(localTimeFormat),
      label: label
    }
  }

  private calculateDifference() {
    const timeRange = this.timeRange;
    const time = {
      start: moment(timeRange.start),
      end: moment(timeRange.end)
    };
    const currentResolution = AppContext.resolutionForTimeRange(this.timeRange);
    let resolution = this.getResolutionHigher(currentResolution);
    let difference = time.end.diff(time.start, resolution, true);
    if (!lodash.inRange(difference, 0.95, 1.05)) {
      difference = time.end.diff(time.start, currentResolution, true);
      resolution = currentResolution;
    }
    return {time, resolution, difference};
  }

  private getResolutionHigher(resolution: TimeResolution): TimeResolution {
    switch (resolution) {
      case TimeResolution.year:
        return TimeResolution.year;
      case TimeResolution.month:
        return TimeResolution.year;
      case TimeResolution.day:
        return TimeResolution.month;
      case TimeResolution.hour:
      case TimeResolution.minute:
        return TimeResolution.day;
    }
  }
}
