import {entityToSourceInfo, SourceInfo, SourcesProvider, TreeViewItem} from "./sources-provider";
import {
    Connection,
    ConnectionType,
    Location,
    Meter,
    MeterType,
    Organisation,
    SourceIds
} from "@flowmaps/flowmaps-typescriptmodels";
import {AppContext} from "../../app-context";
import {SourceType} from "../../views/dashboard/dashboard.context";
import {map, Observable} from 'rxjs';
import {subscribeTo} from "../../common/app-common-utils";
import {Entity} from "../../handlers/entity";

export class OrganisationProvider extends SourcesProvider<Organisation> {

    constructor(private readonly organisationId?: string, private readonly initialSelection?: string[],
                private readonly sourceFilter? : ((item : SourceInfo) => boolean)) {
        super();
    }

    collectData() {
        let observable: Observable<Organisation[]> = subscribeTo("getOrganisations");
        if (this.organisationId) {
            observable = observable.pipe(map(orgs => orgs.filter(o => o.organisationId === this.organisationId)));
        }
        observable.subscribe(o => {
            this.setData(o);
            if (this.initialSelection && this.initialSelection.length > 0) {
                this.selectSources(this.initialSelection);
            }
            this.dataCollected.emit(o);
        });
    }

    setData(data: Organisation[]) {
        let mapped = this.treeViewItemMapper(data);
        if (this.sourceFilter) {
            mapped = mapped.filter(m => this.sourceFilter(m.info))
                .map(m => mapItem(m, this.sourceFilter));
        }
        this.hierarchyData = mapped;
        this.flattenedData = this.flatMapData(this.hierarchyData);
        this.data = this.getData();
        this.selectionUpdated.emit(this.getSelection());
        this.dataHasBeenCollected = true;

        function mapItem(item : TreeViewItem, sourceFilter : ((item : SourceInfo) => boolean)) : TreeViewItem {
            item.subItems = (item.subItems || []).filter(i => sourceFilter(i.info))
                .map(i => mapItem(i, sourceFilter));
            return item;
        }
    }

    treeViewItemMapper(organisations: Organisation[]): TreeViewItem[] {
        if (this.organisationId) {
            const o = organisations[0];
            return o.locations
                .map(l => {
                    const locTreeItem = <TreeViewItem>{
                        icon: AppContext.getIconOfSourceType(SourceType.location),
                        checkbox: true,
                        visible: true,
                        info: entityToSourceInfo(new Entity(o, l))
                    };

                    locTreeItem.subItems = l.connections
                        .map(c => (<TreeViewItem>{
                            icon: AppContext.getIconOfSourceType(SourceType.connection, c.info.connectionType as ConnectionType),
                            checkbox: true,
                            visible: true,
                            parent: locTreeItem,
                            info: entityToSourceInfo(new Entity(o, l, c))
                        }))
                        .sort(this.treeViewComparator);

                    return locTreeItem;
                })
                .sort(this.treeViewComparator);
        }
        return organisations
            .map(o => {
                const orgTreeItem: TreeViewItem = {
                    icon: AppContext.getIconOfSourceType(SourceType.organisation),
                    checkbox: true,
                    visible: true,
                    parent: null,
                    expanded: false,
                    info: entityToSourceInfo(new Entity(o))
                };

                orgTreeItem.subItems = o.locations
                    .map(l => {
                        const locTreeItem: TreeViewItem = {
                            icon: AppContext.getIconOfSourceType(SourceType.location),
                            expanded: false,
                            checkbox: true,
                            visible: true,
                            parent: orgTreeItem,
                            info: entityToSourceInfo(new Entity(o, l))
                        };

                        locTreeItem.subItems = l.connections
                            .map(c => {
                                const connectionItem: TreeViewItem = {
                                    icon: AppContext.getIconOfSourceType(SourceType.connection, c.info.connectionType as ConnectionType),
                                    expanded: false,
                                    checkbox: true,
                                    visible: true,
                                    parent: locTreeItem,
                                    info: entityToSourceInfo(new Entity(o, l, c))
                                };
                                connectionItem.subItems = this.buildMeterTree(c.meters, connectionItem, o, l, c);
                                connectionItem.subItems.sort(this.treeViewComparator);
                                return connectionItem;
                            })
                            .sort(this.treeViewComparator);

                        return locTreeItem;
                    })
                    .sort(this.treeViewComparator);

                return orgTreeItem;
            })
            .sort(this.treeViewComparator);
    }

    sourceSelectionAfterCleanup(): SourceIds {
        const sources = super.sourceSelectionAfterCleanup();
        if (this.organisationId && this.getAllSelectedSourceIds(sources).length === 0) {
            sources.organisationIds.push(this.organisationId);
        }
        return sources;
    }

    buildMeterTree(meters: Meter[], connectionItem: TreeViewItem, o: Organisation, l: Location, c: Connection): TreeViewItem[] {
        const meterItems = meters.map(m =>
            <TreeViewItem> {
                icon: null,
                checkbox: true,
                visible: ((m.details?.type || m.info?.type) !== MeterType.PRIMARY) || ((m.details?.type || m.info?.type) === MeterType.PRIMARY && m.info.virtual),
                info: entityToSourceInfo(new Entity(o, l, c, m)),
                parent: null,
                meterId: m.meterId,
                parentId: m.parentId
            });

        const meterMap = new Map<string, TreeViewItem>();
        meterItems.forEach(item => meterMap.set(item.meterId, item));

        // Build the tree: attach meters with a valid parentId to their parent.
        const rootMeters: TreeViewItem[] = [];
        meterItems.forEach(item => {
            if (item.parentId && meterMap.has(item.parentId)) {
                const parentItem = meterMap.get(item.parentId)!;
                parentItem.subItems = parentItem.subItems || [];
                parentItem.subItems.push(item);
                item.parent = parentItem;
            } else {
                item.parent = connectionItem;
                rootMeters.push(item);
            }
        });
        const sortTree = (items: TreeViewItem[]) => {
            items.sort(this.treeViewComparator);
            items.forEach(item => {
                if (item.subItems && item.subItems.length) {
                    sortTree(item.subItems);
                }
            });
        };
        sortTree(rootMeters);
        return rootMeters;
    }
}