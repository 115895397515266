import {Component, Input} from '@angular/core';
import {AppContext} from "../../../app-context";
import {ConnectionType} from "@flowmaps/flowmaps-typescriptmodels";

@Component({
  selector: 'app-meter-image',
  templateUrl: './meter-image.component.html',
  styleUrls: ['./meter-image.component.scss']
})
export class MeterImageComponent {
  appContext = AppContext;
  @Input() meterType: "PRIMARY" | "INTERMEDIATE" | "GROSS_PRODUCTION" | "INTERMEDIARY";
  @Input() connectionType: "Electricity" | "Gas" | "Heat" | "Water" | "Cooling" | "ChargePoint";
  @Input() width: number = 20;
  @Input() orientation: "vertical" | "horizontal" = "horizontal";

  getConnectionConfig() {
    const connectionTypeEnum = this.connectionType as ConnectionType;
    return this.appContext.getConnectionConfig(connectionTypeEnum);
  }
}
