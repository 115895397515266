import {Injectable} from "@angular/core";
import {map, mergeMap, Observable, of} from "rxjs";
import {
    ApiKey,
    Connection,
    ConnectionType,
    Contract,
    FindUsers,
    LinkedContract,
    Location,
    Meter,
    Organisation,
    UserProfile
} from "@flowmaps/flowmaps-typescriptmodels";
import lodash from "lodash";
import {StandaloneHandler} from "../common/standalone-handler";
import {ComparatorChain} from "../common/comparator-chain";
import {HandleQuery} from "../common/handle-query";
import {sendQuery, subscribeTo} from "../common/app-common-utils";
import {filterByTermArray} from "../common/utils";
import {RefdataUtils} from "../views/refdata/refdata-utils";
import {Entity, EntityType} from "./entity";

@Injectable({
    providedIn: "root"
})
export class AppEntityHandler extends StandaloneHandler {
    contractsComparator: ComparatorChain = new ComparatorChain('contract.contractData.name', 'contract.contractId');
    usersComparator: ComparatorChain = new ComparatorChain('info.lastName', 'info.firstName');

    @HandleQuery("searchUsers")
    searchUsers(term?: string): Observable<UserProfile[]> {
        return subscribeTo("com.flowmaps.api.user.FindUsers", <FindUsers>{
            term: lodash.isEmpty(term) ? null : term
        }).pipe(map(values => values.sort(this.usersComparator.compare)));
    }

    @HandleQuery("getOrganisations")
    getOrganisations(): Observable<Organisation[]> {
        return subscribeTo("com.flowmaps.api.organisation.GetMyOrganisations");
    }

    @HandleQuery("getOrganisation")
    getOrganisation(): Observable<Organisation> {
        return of(null);
    }

    @HandleQuery("searchOrganisations")
    searchOrganisations(term: string): Observable<Organisation[]> {
        return sendQuery("getOrganisations")
            .pipe(map(values => values.filter(filterByTermArray([term]))))
            .pipe(map(values => values.sort(RefdataUtils.organisationComparator.compare)))
            .pipe(map(values => values.slice(0, 100)));
    }

    @HandleQuery("getLocations")
    getLocations(): Observable<Location[]> {
        return this.getOrganisations().pipe(map((o: Organisation[]) => o.flatMap(o => o.locations)));
    }

    @HandleQuery("getLocationsAsEntities")
    getLocationsAsEntities(): Observable<Entity[]> {
        return this.getEntities()
            .pipe(map((l: Map<string, Entity>) => Array.from(l.values())))
            .pipe(map((l: Entity[]) => l.filter(l => l.getEntityType() === EntityType.location)));
    }

    @HandleQuery("searchLocations")
    searchLocations(term: string): Observable<Entity[]> {
        return sendQuery("getLocationsAsEntities")
            .pipe(map((values: Entity[]) => values.filter(filterByTermArray([term], Entity.getExcludedFilterFields()))))
            .pipe(map(values => values.sort(RefdataUtils.locationsComparator.compare)))
            .pipe(map(values => values.slice(0, 100)));
    }

    @HandleQuery("getLocationsPerOrganisation")
    getLocationsPerOrganisation({ organisationId, term }: { organisationId: string; term: string }): Observable<Entity[]> {
        return sendQuery("getLocationsAsEntities").pipe(
                map((locations: Entity[]) => locations.filter(e => e.organisation.organisationId === organisationId)),
                map(locations => locations.filter(filterByTermArray([term], Entity.getExcludedFilterFields()))),
                map(locations => locations.sort(RefdataUtils.locationsComparator.compare)),
                map(locations => locations.slice(0, 100)));
    }

    @HandleQuery("getConnections")
    getConnections(): Observable<Connection[]> {
        return this.getLocations()
            .pipe(map((l: Location[]) => l.flatMap(l => l.connections)));
    }

    @HandleQuery("getConnectionsAsEntities")
    getConnectionsAsEntities(): Observable<Entity[]> {
        return this.getEntities()
            .pipe(map((l: Map<string, Entity>) => Array.from(l.values())))
            .pipe(map((l: Entity[]) => l.filter(l => l.getEntityType() === EntityType.connection)));
    }

    @HandleQuery("getMeters")
    getMeters(): Observable<Meter[]> {
        return this.getConnections()
            .pipe(map((l: Connection[]) => l.flatMap(l => l.meters)));
    }

    @HandleQuery("getMetersAsEntities")
    getMetersAsEntities(): Observable<Entity[]> {
        return subscribeTo("getEntities")
            .pipe(map((l: Map<string, Entity>) => Array.from(l.values())))
            .pipe(map(e => e.filter(e => e.getEntityType() === EntityType.meter)));
    }

    @HandleQuery("getChargePointMeters")
    getChargePointMeters(): Observable<Meter[]> {
        return this.getConnections().pipe(
            map((connections: Connection[]) => connections.filter(connection => connection.info.connectionType === ConnectionType.ChargePoint)),
            map((chargePointConnections: Connection[]) => chargePointConnections.flatMap(connection => connection.meters))
        );
    }


    @HandleQuery("getEntity")
    getEntity(entityId: string): Observable<Entity> {
        return entityId ? this.getEntities()
            .pipe(map(e => e.get(entityId))) : of(null);
    }

    @HandleQuery("getEntities", {caching: true})
    getEntities(): Observable<Map<string, Entity>> {
        return subscribeTo("getOrganisations").pipe(map((o: Organisation[]) => this.createEntities(o)));
    }

    @HandleQuery("getEntitiesById")
    getEntitiesById(entityIds: string[]): Observable<Entity[]> {
        return entityIds ? subscribeTo("getEntities")
                .pipe(map((e: Map<string, Entity>) => entityIds.map(i => e.get(i)).filter(e => e)))
            : of(null);
    }

    @HandleQuery("getContractEntities")
    getContractEntities(entityIds: string[]): Observable<ContractEntity[]> {
        return entityIds ? this.getOrganisations()
            .pipe(map(orgs => entityIds.map(i => this.findContractEntityById(orgs, i))
                .filter(c => c))) : of(null);
    }

    @HandleQuery("getContract")
    getContract(contractId: string): Observable<ContractEntity> {
        return contractId
            ? this.getOrganisations().pipe(map((o: Organisation[]) => this.findContractEntityById(o, contractId)))
            : of(null);
    }

    @HandleQuery("getContracts")
    getContracts(): Observable<ContractEntity[]> {
        return subscribeTo("com.flowmaps.api.organisation.GetMyContracts")
            .pipe(map((c: Contract[]) => c.map(i => i.contractId)))
            .pipe(mergeMap(ids => sendQuery("getContractEntities", ids)))
            .pipe(map(values => values.sort(this.contractsComparator.compare)));
    }

    @HandleQuery("searchContracts")
    searchContracts(term: string): Observable<ContractEntity[]> {
        return this.getContracts().pipe(map((values: ContractEntity[]) => values.filter(filterByTermArray([term]))))
            .pipe(map(values => values.slice(0, 100)));
    }

    @HandleQuery("getLinkedContractEntities")
    getLinkedContractEntities(contractId: string): Observable<LinkedContractEntity[]> {
        return contractId
            ? this.getContract(contractId)
                .pipe(mergeMap(c => sendQuery("getOrganisations")
                    .pipe(map((o: Organisation[]) => this.getLinkedContracts(o, c)))))
            : of(null);
    }

    @HandleQuery("getApiKeys")
    getApiKeys(): Observable<ApiKey[]> {
        return subscribeTo("com.flowmaps.api.user.GetUserProfile").pipe(map((userProfile: UserProfile) => userProfile.apiKeys));
    }

    private findContractEntityById = (organisations: Organisation[], contractId: string): ContractEntity => {
        const org = organisations.find(o => o.contracts.some(c => c.contractId == contractId));
        const contract = org.contracts.find(c => c.contractId === contractId);
        return org ? {
            organisation: org,
            contract: contract
        } : null;
    }

    private createEntities = (organisations: Organisation[]): Map<string, Entity> => {
        const entities = new Map<string, Entity>();
        organisations.forEach(o => {
            entities.set(o.organisationId, new Entity(o));
            o.locations.forEach(l => {
                entities.set(l.locationId, new Entity(o, l));
                l.connections.forEach(c => {
                    entities.set(c.connectionId, new Entity(o, l, c));
                    c.meters.forEach(m => entities.set(m.meterId, new Entity(o, l, c, m)));
                });
            });
        });
        return entities;
    }

    private getLinkedContracts = (orgs: Organisation[], contract: ContractEntity): LinkedContractEntity[] => {
        const linkedContracts: LinkedContractEntity[] = [];
        orgs.forEach((org: Organisation) => {
            const filteredOrgLinkedContracts = org.linkedContracts.filter(
                (c) => c.contractId === contract.contract.contractId);

            if (filteredOrgLinkedContracts.length > 0) {
                linkedContracts.push({
                    linkedContracts: filteredOrgLinkedContracts,
                    entity: new Entity(org)
                });
            }

            org.locations.forEach((location) => {
                location.connections.forEach((connection: Connection) => {
                    const filteredConnectionLinkedContracts = connection.linkedContracts.filter(
                        (c) => c.contractId === contract.contract.contractId);

                    if (filteredConnectionLinkedContracts.length > 0) {
                        linkedContracts.push({
                            linkedContracts: filteredConnectionLinkedContracts,
                            entity: new Entity(org, location, {
                                ...connection,
                                linkedContracts: filteredConnectionLinkedContracts,
                            })
                        });
                    }
                });
            });
        });
        return linkedContracts;
    }
}

export interface ContractEntity {
    contract: Contract;
    organisation: Organisation;
}

export interface LinkedContractEntity {
    linkedContracts: LinkedContract[];
    entity: Entity;
}