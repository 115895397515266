import {Component, Input} from '@angular/core';
import {AppContext} from "../../../../app-context";
import {ConnectionType, DataType} from "@flowmaps/flowmaps-typescriptmodels";
import {EntityType} from "../../../../handlers/entity";
import {SourcesProvider} from "../../../../utils/source-providers/sources-provider";
import {Handler} from "../../../../common/handler";
import {View} from "../../../../common/view";
import {HandleQuery} from "../../../../common/handle-query";
import {Observable, of} from "rxjs";

@Component({
    selector: 'app-report-entity-performance-page',
    templateUrl: './report-entity-performance-page.component.html',
    styleUrls: ['./report-entity-performance-page.component.scss']
})
@Handler()
export class ReportEntityPerformancePageComponent extends View {
    appContext = AppContext;
    @Input() sourceProvider: SourcesProvider<any>;
    @Input() consumptionType: DataType;
    @Input() productionType: DataType;
    @Input() connectionType: ConnectionType | string;

    locationType: EntityType = EntityType.location;

    pageSize: number = 10;

    hasSubMeters = (): boolean => this.sourceProvider.hasSubMetersForConnectionType(
        this.connectionType as ConnectionType);

    hasSourceForConnectionType = () => this.sourceProvider.hasSourceForConnectionType(
        this.connectionType as ConnectionType);

    get showByLocation(): boolean {
        return this.sourceProvider.getAllSourcesByType(this.locationType).length > 1;
    }

    get entityConsumptionType(): DataType {
        if (!this.showByLocation) {
            return this.consumptionType === DataType.electricityConsumption
                ? DataType.electricityIntermediateConsumption : this.consumptionType;
        }
        return this.consumptionType;
    }

    get entityProductionType(): DataType {
        if (!this.showByLocation) {
            return this.productionType === DataType.electricityFeedIn
                ? DataType.electricityGrossProduction : this.productionType;
        }
        return this.productionType;
    }

    @HandleQuery("getEntityConsumptionType")
    getEntityConsumptionType(): Observable<DataType> {
        return of(this.entityConsumptionType);
    }
}
