import {entityToSourceInfo, SourcesProvider, TreeViewItem} from "./sources-provider";
import {ConnectionType, Location, Organisation} from "@flowmaps/flowmaps-typescriptmodels";
import {lodash} from "../../common/utils";
import {AppContext} from "../../app-context";
import {SourceType} from "../../views/dashboard/dashboard.context";
import {Entity, EntityType} from "../../handlers/entity";
import {sendQuery} from "../../common/app-common-utils";
import {map} from "rxjs";

export class LocationProvider extends SourcesProvider<Location> {

    collectData() {
        const locationId = this.getSelectedSourcesByType(EntityType.location)[0];
        sendQuery("getOrganisations")
            .pipe(map((o: Organisation[]) => o.flatMap(o => o.locations)
                .find(l => l.locationId === locationId)))
            .subscribe(l => {
                this.setData([l]);
                this.dataCollected.emit([l]);
            });
    }

    setData(locations: Location[]) {
        this.hierarchyData = this.treeViewItemMapper(locations);
        this.flattenedData = this.flatMapData(this.hierarchyData);
        this.data = this.getData();
        this.dataHasBeenCollected = true;
        this.selectionUpdated.emit(this.getSelection());
    }

    treeViewItemMapper(locations: Location[]): TreeViewItem[] {
        return lodash.chain(locations)
            .map(l => {
                const locTreeItem = <TreeViewItem>{
                    icon: AppContext.getIconOfSourceType(SourceType.location),
                    checkbox: true,
                    visible: true,
                    info: entityToSourceInfo(new Entity(null, l))
                };

                locTreeItem.subItems = lodash.chain(l.connections)
                    .map(c => {
                        const connectionItem = <TreeViewItem>{
                            icon: AppContext.getIconOfSourceType(SourceType.connection, c.info.connectionType as ConnectionType),
                            checkbox: true,
                            visible: true,
                            parent: locTreeItem,
                            info: entityToSourceInfo(new Entity(null, l, c))
                        };

                        connectionItem.subItems = lodash.chain(c.meters)
                            .map(m => (<TreeViewItem>{
                                icon: null,
                                checkbox: true,
                                visible: true,
                                parent: connectionItem,
                                info: entityToSourceInfo(new Entity(null, l, c, m))
                            }))
                            .sort(this.treeViewComparator)
                            .value();

                        return connectionItem;
                    })
                    .sort(this.treeViewComparator)
                    .value();

                return locTreeItem;
            })
            .sort(this.treeViewComparator)
            .value();
    }
}