import {Component, ElementRef, inject, ViewChild} from '@angular/core';
import {DashboardContext} from "./dashboard.context";
import {Observable, of} from "rxjs";
import {DeleteDashboard, Location, SaveDashboard} from "@flowmaps/flowmaps-typescriptmodels";
import {cloneObject, removeIf, uuid} from "../../common/utils";
import {AppContext} from "../../app-context";
import {ActivatedRoute} from "@angular/router";
import {asSourceIds, SourceInfo, SourcesProvider} from "../../utils/source-providers/sources-provider";
import {Handler} from "../../common/handler";
import {View} from "../../common/view";
import {AppCommonUtils} from "../../common/app-common-utils";
import {Entity, EntityType} from "../../handlers/entity";
import {downloadBulkMonthData} from "../refdata/connections/connections-overview/bulk-data-download";
import {
    DataQueryFilters,
    MeasurementsHandlerComponentData
} from "../measurements-component/measurements-handler.component";
import {HandleQuery} from "../../common/handle-query";
import {MomentDateFieldRange} from "../../common/date/date-range/date-field-range";
import {HandleEvent} from "../../common/handle-event";
import {Dashboard} from "./dashboard.types";
import {OrganisationProvider} from "../../utils/source-providers/organisation-provider";
import {HandleCommand} from "../../common/handle-command";

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    host: {
        class: 'w-100'
    }
})
@Handler()
export class DashboardComponent extends View {
    appContext = AppContext;
    context = DashboardContext;

    private route: ActivatedRoute = inject(ActivatedRoute);

    @ViewChild('leftMenuCollapse', {static: true}) filtersModal: ElementRef;
    @ViewChild('sourceListDropdown') sourceListDropdown: ElementRef;
    locationEntity: Entity;

    headerTitle: string;
    private filters: DataQueryFilters;

    measurementsHandlerData: MeasurementsHandlerComponentData = { };

    addresses: Location[] = [];

    sourceProvider: SourcesProvider<any>;
    dashboard: Dashboard = {info: {}};
    protected isPortfolio: boolean = true;
    protected showPerformanceByMeter: boolean = false;

    constructor() {
        super();
        this.sourceProvider = new OrganisationProvider();
        this.route.params.subscribe(params => {
            this.route.queryParams.subscribe(queryParams => {
                const param = params['dashboard'];
                this.dashboard = param ? DashboardContext.base64ToDashboard(param) : DashboardContext.defaultDashboard();
                this.measurementsHandlerData = {
                    timeRange: {
                        resolution: this.dashboard.info.resolution,
                        label: this.dashboard.info.predefinedTimeRange,
                        ...this.dashboard.info.timeRange
                    },
                    chartOptions: this.dashboard.info.chartOptions
                };
                this.sourceProvider.selectedSources = this.dashboard.info.sources;
                if (this.sourceProvider.dataHasBeenCollected) {
                    this.measurementsHandlerData.selectedSources = this.sourceProvider.getTreeViewSelection().map(s => s.info);
                    this.measurementsHandlerData.allSelectedSources = this.sourceProvider.getFlatSourceSelection();
                    this.sourceProvider.selectionUpdated.emit(this.sourceProvider.getSelection());
                }
            });
        });
    }

    private getAddresses() {
        const sourceSelection = this.filters.sources;
        const items = (sourceSelection?.length > 0 ? sourceSelection
            : this.filters.allSelectedSources?.filter(a => a.type === EntityType.location)) || []
        return items.flatMap(this.getLocations);
    }

    private getLocations(item: SourceInfo): Location[] {
        switch (item.type) {
            case EntityType.organisation:
                return item.source.organisation.locations;
            case EntityType.location:
                return [item.source.location];
            case EntityType.connection:
                return [item.source.location];
            case EntityType.meter:
                return [item.source.location];
        }
    }

    saveDashboard = (): void => {
        const savedDashboard: Dashboard = cloneObject(this.dashboard);
        savedDashboard.dashboardId = uuid();
        const command: SaveDashboard = {
            info: savedDashboard.info,
            dashboardId: savedDashboard.dashboardId,
            userId: AppContext.userProfile.userId
        };
        this.sendCommand("com.flowmaps.api.user.SaveDashboard", command, () =>
            AppCommonUtils.navigateToUrl("/dashboard/" + encodeURIComponent(DashboardContext.dashboardToBase64(savedDashboard))));
    }

    deleteDashboard = (id): void => {
        const command: DeleteDashboard = {
            dashboardId: id,
            userId: AppContext.userProfile.userId
        };
        this.sendCommand("com.flowmaps.api.user.DeleteDashboard", command, () => {
            removeIf(AppContext.userProfile.dashboards, d => d.dashboardId === id);
            if (this.dashboard?.dashboardId === id) {
                AppCommonUtils.navigateToUrl("/");
            }
        });
    }

    updateAndNavigateToDashboard() {
        if (this.filters && this.filters.timeRange && this.filters.sources) {
            this.dashboard.info.sources = asSourceIds(this.filters.sources);
            this.dashboard.info.timeRange = { start: this.filters.timeRange.start, end: this.filters.timeRange.end };
            this.dashboard.info.resolution = this.filters.timeRange.resolution;
            this.dashboard.info.predefinedTimeRange = this.filters.timeRange.label;
            const dashboardEncoded = DashboardContext.dashboardToBase64(this.dashboard);
            const newUrlEncoded = `/dashboard/${encodeURIComponent(dashboardEncoded)}`;
            if (newUrlEncoded !== window.location.pathname) {
                return AppCommonUtils.navigateToUrl(newUrlEncoded);
            }
        }
    }

    downloadMonthlyData = () => downloadBulkMonthData(this.sourceProvider, this.filters.timeRange);

    @HandleEvent("filtersChanged")
    onFiltersChanged(filters: DataQueryFilters, ev: CustomEvent) {
        if (filters.fullScreen) {
            ev.stopPropagation();
            return ;
        }
        this.filters = filters;
        this.addresses = this.getAddresses();
        this.headerTitle = this.filters.sources?.length ? this.filters.sources.map(s => s.name).join(", ") : null;
        this.updateAndNavigateToDashboard();
        ev.stopPropagation();
        this.locationEntity = this.isPortfolio ? null : this.filters.allSelectedSources
            .find(s => s.type === EntityType.location).source;
        this.updateShowPerformanceByMeter();
        return false;
    }

    @HandleQuery("getTimeRanges")
    getTimeRanges(): Observable<MomentDateFieldRange[]> {
        return of(DashboardContext.defaultRanges);
    }

    @HandleEvent("dashboardTypeChanged")
    dashboardTypeChanged(isPortfolio: boolean) {
        this.isPortfolio = isPortfolio;
        this.updateShowPerformanceByMeter();
    }

    @HandleCommand("selectEntity")
    selectEntity(entityId: string) {
        this.sourceProvider.selectSourceById(entityId);
    }

    private updateShowPerformanceByMeter() {
        this.showPerformanceByMeter = this.isPortfolio && (this.filters.sources?.length && this.filters.sources
            .every(s => [EntityType.connection, EntityType.meter].includes(s.type)));
    }
}
