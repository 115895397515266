import {Component, ElementRef, inject, ViewChild} from '@angular/core';
import {Attachment, GetMyReports, Organisation, Report} from "@flowmaps/flowmaps-typescriptmodels";
import {ActivatedRoute, Router} from "@angular/router";
import {ReportHtmlToPdfComponent} from "../report-html-to-pdf/report-html-to-pdf.component";
import {combineLatest, map, mergeMap, Observable} from "rxjs";
import {SourcesProvider} from "../../../utils/source-providers/sources-provider";
import moment from "moment";
import {Handler} from "../../../common/handler";
import {View} from "../../../common/view";
import {writeBulkMonthDataAsExcel} from "../../refdata/connections/connections-overview/bulk-data-download";
import {writeConnectionsAsExcel} from "../../refdata/connections/connections-overview/connections-list-excel";
import {Entity, EntityType} from "../../../handlers/entity";
import {sendQuery} from "../../../common/app-common-utils";

@Component({
    selector: 'app-report-runner',
    templateUrl: './report-runner.component.html',
    styleUrls: ['./report-runner.component.scss']
})
@Handler()
export class ReportRunnerComponent extends View {
    reportId: string;
    report: Report;
    requestId: number;
    target: string;

    @ViewChild(ReportHtmlToPdfComponent) htmlToPdfComponent: ReportHtmlToPdfComponent;
    private router: Router = inject(Router);
    private route: ActivatedRoute = inject(ActivatedRoute);

    constructor() {
        super();
        this.route.params.subscribe(params => {
            this.route.queryParams.subscribe(queryParams => {
                console.info("Report page has been opened");

                this.reportId = queryParams['reportId'];
                this.requestId = queryParams['requestId'];
                this.target = queryParams['target'];

                if (!this.reportId) {
                    this.router.navigateByUrl("/report/default");
                    return;
                }

                this.sendQuery("com.flowmaps.api.reporting.GetMyReports", <GetMyReports>{}, {caching: false})
                    .subscribe((reports: Report[]) => {
                        this.report = reports.find(r => r.reportId === this.reportId);
                    });
            });
        });
    }

    sendReport = async () => {
        console.info("Creating PDF");
        let jsPDF, pdfFile, error;
        try {
            jsPDF = await this.htmlToPdfComponent.createPdf();
            pdfFile = await this.blobToBase64(jsPDF.output('blob')) as string;
        } catch (e) {
            error = e;
        }
        const sourceProvider = this.htmlToPdfComponent.sourceProvider;
        const diffInMonths = moment(this.htmlToPdfComponent.timeRange.end).diff(moment(this.htmlToPdfComponent.timeRange.start), 'month', true);
        const attachments: Observable<Attachment>[] = diffInMonths >= 1
            ? [writeBulkMonthDataAsExcel(sourceProvider, this.htmlToPdfComponent.timeRange).pipe(map(d => ({
                fileName: "bulk-data-monthly.xlsx",
                mediaType: "application/vnd.ms-excel",
                base64Data: d
            })))] : [getConnectionsExcel(this.elementRef, sourceProvider, writeConnectionsAsExcel).pipe(map(c => ({
                fileName: "selected-connections.xlsx",
                mediaType: "application/vnd.ms-excel",
                base64Data: c
            })))];
        combineLatest(attachments).subscribe(attachments => {
            const container = document.createElement('div');
            container.id = "report-result";
            const allAttachments = attachments.concat({
                fileName: "report.pdf",
                mediaType: "application/pdf",
                base64Data: pdfFile.substring(pdfFile.indexOf(',') + 1)
            });
            container.setAttribute("data-attachments", JSON.stringify(allAttachments));
            if (error) {
                container.setAttribute("data-error", error);
            }
            this.elementRef.nativeElement.appendChild(container);
            console.info("Attachments added to DOM");
        });
    }

    blobToBase64 = (blob) => new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
    });
}

export function getConnectionsExcel(elementRef: ElementRef, sourcesProvider: SourcesProvider<any>, writeMethod: (connections: Entity[]) => any): Observable<any> {
    return sendQuery("getOrganisations", undefined, undefined, elementRef)
        .pipe(map((orgs: Organisation[]) => orgs.flatMap(o => o.locations
            .flatMap(l => l.connections.map(c => new Entity(o, l, c))))))
        .pipe(map((cons: Entity[]) =>
            sourcesProvider.getAllSourcesByType(EntityType.connection)
                .map(s => cons.find(c => c.connection.connectionId === s.id))
                .filter(s => s)))
        .pipe(mergeMap((s: Entity[]) => writeMethod(s) || s));
}