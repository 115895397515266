import {Component, Input, OnInit} from '@angular/core';
import {AppContext} from "../../../../app-context";
import {RefdataUtils} from "../../refdata-utils";
import {MeterDetails, MeterType, UpdateMeterDetails} from "@flowmaps/flowmaps-typescriptmodels";
import {SentenceCasePipe} from "../../../../common/sentence-case.pipe";
import {View} from "../../../../common/view";
import {Handler} from "../../../../common/handler";
import {Entity} from "../../../../handlers/entity";
import {cloneDeep} from "lodash";
import {trackByIndex} from "../../../../common/utils";

@Component({
    selector: 'app-meter-details',
    templateUrl: './meter-details.component.html',
    styleUrls: ['./meter-details.component.scss']
})
@Handler()
export class MeterDetailsComponent extends View implements OnInit {
    appContext = AppContext;
    refDataUtils = RefdataUtils;
    details: MeterDetails;
    @Input() data: MeterComponentData;
    meterTypes: MeterType[] = [MeterType.PRIMARY, MeterType.INTERMEDIATE, MeterType.GROSS_PRODUCTION];

    protected readonly trackByIndex = trackByIndex;
    meterTypeFormatter = (v: string): string => SentenceCasePipe.format(v);

    ngOnInit(): void {
        this.details = cloneDeep(this.data.entity.meter.details) || cloneDeep(this.data.entity.meter.info);
    }

    updateInfo = () => {
        this.data.entity.meter.details = this.details;
        this.sendCommand("com.flowmaps.api.organisation.UpdateMeterDetails", <UpdateMeterDetails>{
            organisationId: this.data.entity.organisation.organisationId,
            locationId: this.data.entity.location.locationId,
            meterId: this.data.entity.meter.meterId,
            connectionId: this.data.entity.connection.connectionId,
            details: this.details
        });
    };
}

export interface MeterComponentData {
    entity: Entity;
}