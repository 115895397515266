import {Component, Input} from '@angular/core';
import {BaseChart} from "../base-chart.component";
import {ChartConfiguration, Plugin, ScaleOptions} from "chart.js";
import {ChartTypeRegistry, ScaleOptionsByType} from "chart.js/dist/types";

@Component({
    selector: 'app-scatter-chart',
    templateUrl: './scatter-chart.component.html',
    styleUrls: ['./scatter-chart.component.scss']
})
export class ScatterChartComponent extends BaseChart<'scatter'> {
    @Input() xLabels: string[];
    @Input() yLabels: string[];
    @Input() lineAxes: ScatterData;
    @Input() visibleBuildingTypes: string[];
    @Input() scales: {
        [key: string]: ScaleOptionsByType<ChartTypeRegistry['scatter']['scales']>;
    } = {};
    @Input() plugins: Plugin<'scatter'>[] = [];
    @Input() suggestedMinY: number;
    @Input() pluginOptions: any = {};
    @Input() gridLineColor: string;

    defaultOptions: ChartConfiguration<'scatter'>['options'] = {
        parsing: false,
        normalized: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                display: true,
                grid: {
                    display: false
                },
                offset: true
            },
            y: {
                display: true,
                grid: {
                    display: false
                },
                offset: true
            }
        }
    };

    getOptions(): ChartConfiguration<'scatter'>['options'] {
        const scales: any = this.getDefaultScales();
        Object.entries(this.scales).forEach(s => scales[s[0]] = s[1]);
        const options: ChartConfiguration<'scatter'>['options'] = {
            parsing: false,
            normalized: true,
            maintainAspectRatio: false,
            scales: scales,
            layout: {
                padding: {
                    bottom: 30
                }
            },
            animation: {
                duration: 0
            },
            plugins: {
                datalabels: {
                    display: false
                },
                legend: {
                    display: false
                },
                title: {
                    display: true,
                    text: this.chartTitle,
                    font: {
                        size: 16
                    }
                },
                decimation: {
                    enabled: true,
                    algorithm: 'lttb',
                    samples: 500
                }
            }
        };
        Object.entries(this.pluginOptions).forEach(e => options.plugins[e[0]] = e[1]);
        return options;
    };

    private getDefaultScales = (): {
        [key: string]: ScaleOptions;
    } => ({
        x: {
            labels: this.xLabels,
            suggestedMin: 0,
            suggestedMax: this.xLabels.length - 1,
            grid: {
                display: false
            },
            offset: true,
            ticks: {
                stepSize: 1,
                autoSkip: false,
                source: "labels",
                color: "white",
                callback: (label, index, labels) => {
                    return this.xLabels[label];
                }
            }
        },
        y: {
            display: true,
            suggestedMin: this.suggestedMinY,
            suggestedMax: (this.yLabels.length - 1) * 100,
            border: {
                dash: [0]
            },
            grid: {
                display: true,
                color: this.gridLineColor,
                lineWidth: 1,
            },
            ticks: {
                autoSkip: false,
                source: "labels",
                color: "white",
                callback: (label, index, labels) => {
                    const l: number = label as number;
                    if (l < 0) {
                        return "";
                    }
                    return this.yLabels[l / 100];
                }
            },
            beginAtZero: false
        }
    });
}

export interface ScatterData {
    data: ScatterRecord[];
}

interface ScatterRecord {
    x: number;
    y: number;
    label: string;
}