import {Component, Input, OnInit} from '@angular/core';
import {View} from "../../../../common/view";
import {AppContext} from "../../../../app-context";
import {RefdataUtils} from "../../refdata-utils";
import {LocationInfo, Organisation, UpsertLocation} from "@flowmaps/flowmaps-typescriptmodels";
import {LocationComponentData} from "../location-details/location-details.component";

@Component({
  selector: 'app-move-location-modal',
  templateUrl: './move-location-modal.component.html',
  styleUrls: ['./move-location-modal.component.css']
})
export class MoveLocationModalComponent extends View implements OnInit {
  appContext = AppContext;
  refDataUtils = RefdataUtils;
  @Input() data: LocationComponentData;
  info: LocationInfo;
  organisation: Organisation;
  ngOnInit(): void {
    this.organisation = this.data.entity.organisation;
  }

  searchOrganisations = (term: string) => this.sendQuery("searchOrganisations", term);

  moveLocation = () => {
    this.sendCommand("com.flowmaps.api.organisation.MoveLocation", <UpsertLocation> {
      organisationId: this.organisation.organisationId,
      targetOrganisationId: this.data.entity.organisation.organisationId,
      locationId: this.data.entity.location.locationId,
      info: this.info,
      refdata: this.data.entity.location?.refdata
    });
  };

  hasActiveMeters(location: any): boolean {
    if (!location || !location.connections) {
      return false;
    }
    return location.connections.some((connection: any) => connection.meters && connection.meters.length > 0);
  }
}